import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import help_drawer_icon from "../../component/Images/help_drawer_icon.png";
import "./UserHelpandSupport.scss";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from "../../firebaseConfig/firebaseConfig";
import ForumIcon from "@mui/icons-material/Forum";
import { useNavigate } from "react-router-dom";

function UsereHelpandSupport() {
  const [open, setOpen] = useState(false);
  const [text, setText] = useState("");
  const [title, setTitle] = useState("");
  const [data, setData] = useState({
    name: JSON.parse(
      localStorage.getItem(process.env.REACT_APP_USERS_LOCALSTORAGE)
    ).name,
    email: JSON.parse(
      localStorage.getItem(process.env.REACT_APP_USERS_LOCALSTORAGE)
    ).email,
    mobile: JSON.parse(
      localStorage.getItem(process.env.REACT_APP_USERS_LOCALSTORAGE)
    ).mobile,
    subject: "",
    message: "",
    uid: JSON.parse(
      localStorage.getItem(process.env.REACT_APP_USERS_LOCALSTORAGE)
    ).idUser,
  });
  const navigate = useNavigate();
  const handleSubmit = () => {
    if (data.email.trim() == "") {
      setText(process.env.REACT_APP_ENTER_EMAIL_MODAL_TEXT);
      setTitle(process.env.REACT_APP_ERROR_MODAL_TITLE);
      setOpen(true);
    } else if (/^[a-zA-Z0-9]+@+[a-zA-Z0-9]+.+[A-z]/.test(data.email) == false) {
      setText(process.env.REACT_APP_INVALID_EMAIL_MODAL_TEXT);
      setTitle(process.env.REACT_APP_ERROR_MODAL_TITLE);
      setOpen(true);
    } else if (data.name.trim() == "") {
      setText(process.env.REACT_APP_PLEASE_ENTER_NAME_TEXT);
      setTitle(process.env.REACT_APP_ERROR_MODAL_TITLE);
      setOpen(true);
    } else if (data.mobile.trim() == "") {
      setText(process.env.REACT_APP_MOBILE_VALIDATION_TEXT_MODAL);
      setTitle(process.env.REACT_APP_ERROR_MODAL_TITLE);
      setOpen(true);
    } else if (data.mobile.length < 10 || data.mobile.length > 15) {
      setText(
        process.env.REACT_APP_MODILE_NUMBER_VALIDATION_TEXT_FOR_MOBILE_NUMBER
      );
      setTitle(process.env.REACT_APP_ERROR_MODAL_TITLE);
      setOpen(true);
    } else if (data.subject.trim() == "") {
      setText(process.env.REACT_APP_ENTER_YOUR_SUBJECT_TEXT);
      setTitle(process.env.REACT_APP_ERROR_MODAL_TITLE);
      setOpen(true);
    } else if (data.message.trim() == "") {
      setText(process.env.REACT_APP_ENTER_YOUR_MESSAGE_TEXT);
      setTitle(process.env.REACT_APP_ERROR_MODAL_TITLE);
      setOpen(true);
    } else {
      let obj = {
        createdAt: serverTimestamp(),
        email: data.email.trim(),
        message: data.message,
        mobile: data.mobile,
        name: data.name,
        status: false,
        suject: data.subject,
        uid: data.uid,
        version: process.env.REACT_APP_VERSION,
        documentId: "",
        platform: process.env.REACT_APP_PLATFORM,
      };
      addDoc(
        collection(db, process.env.REACT_APP_HELP_AND_SUPPORT_COLLECTION_NAME),
        obj
      ).then(() => {
        setOpen(true);
        setTitle(process.env.REACT_APP_REQUESTED_ADDED_TITLE);
        setText(process.env.REACT_APP_GET_YOU_SHORTLY_TEXT);
        setData({
          subject: "",
          message: "",
        });
      });
    }
  };

  const handleHelpAndSupportChat = () => {
    navigate("/userhelpandsupport");
  };
  return (
    <>
      <Box className="userhelpHeadingMain">
        <Box className="userhelpHeadingdiv">
          <img alt="" src={help_drawer_icon} id="userhelpimg" />
          <Box className="userhelpvrline" />
          <Typography variant="h6" id="userhelpheading">
            {process.env.REACT_APP_MY_HELP_SUPPORT_HEADING}
          </Typography>
        </Box>

        <Box className="userhelpline" />
      </Box>
      <Box className="userhelpmaindiv">
        <Box className="userhelpinput">
          <TextField
            type="text"
            placeholder={process.env.REACT_APP_MY_PROFILE_EMAIL_TEXTFIELD}
            variant="outlined"
            value={data.email}
            onChange={(e) => setData({ ...data, email: e.target.value })}
            focused={false}
            id="userhelp-form-field"
            fullWidth
            size="small"
            style={{ marginTop: "20px" }}
          />
        </Box>
        <Box className="userhelpinput">
          <TextField
            type="text"
            placeholder={process.env.REACT_APP_NAME_PLACEHOLDER}
            variant="outlined"
            value={data.name}
            onChange={(e) => setData({ ...data, name: e.target.value })}
            focused={false}
            id="userhelp-form-field"
            fullWidth
            size="small"
          />
        </Box>
        <Box className="userhelpinput">
          <TextField
            type="tel"
            placeholder={process.env.REACT_APP_MOBILE_NUMBER_PLACEHOLDER}
            variant="outlined"
            focused={false}
            value={data.mobile}
            onChange={(e) => setData({ ...data, mobile: e.target.value })}
            id="userhelp-form-field"
            fullWidth
            size="small"
          />
        </Box>
        <Box className="userhelpinput">
          <TextField
            type="text"
            placeholder={process.env.REACT_APP_YOUR_SUBJECT}
            variant="outlined"
            focused={false}
            value={data.subject}
            onChange={(e) => setData({ ...data, subject: e.target.value })}
            id="userhelp-form-field"
            fullWidth
            size="small"
          />
        </Box>
        <Box className="userhelpinputa">
          <TextField
            type="text"
            placeholder={process.env.REACT_APP_WRITE_YOUR_SUBJECT_TEXT}
            variant="outlined"
            focused={false}
            value={data.message}
            onChange={(e) => setData({ ...data, message: e.target.value })}
            id="userhelp-form-field-text"
            fullWidth
            size="small"
            multiline
            rows={4}
          />
        </Box>
        <Box className="userhelpbottombox">
          <Box>
            <Button
              variant="contained"
              id="userhelp"
              onClick={() => handleSubmit()}
            >
              {process.env.REACT_APP_SUBMIT_BUTTON}
            </Button>
          </Box>
        </Box>
        <Typography
          variant="h6"
          id="userhelpheading"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "var(--white)",
          }}
        >
          ---&nbsp;OR&nbsp;---
        </Typography>
        <Box className="userhelpbottombox">
          <Box>
            <Button
              variant="contained"
              id="userhelp"
              onClick={() => handleHelpAndSupportChat()}
              style={{ textTransform: "none", width: "80vw" }}
            >
              <ForumIcon className="icon" />
              &nbsp;&nbsp; {process.env.REACT_APP_CHAT_WITH_TEAM_BUTTON}
            </Button>
          </Box>
        </Box>
      </Box>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        className="popup"
      >
        <DialogTitle className="dialog-title-background typography-loginpage">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            className="typography-loginpage"
            style={{ marginBottom: "20px" }}
          >
            {text}
          </DialogContentText>
          <Button
            className="typography-loginpage"
            onClick={() => setOpen(false)}
          >
            {process.env.REACT_APP_MODAL_OKAY_BUTTON}
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default UsereHelpandSupport;
