import Adminheader from "./component/Header/Admin/Adminheader";
import HelpandSupport from "./component/Helpandsupport/Helpandsupport";
import Uploadphoto from "./component/UploadPhoto/Uploadphoto";
import Uploadvideo from "./component/UploadVideo/Uploadvideo";
import MainBackhead from "./User/Headers/BackHead/MainBackhead";
import Mainhead from "./User/Headers/MainHead/Mainhead";
import Myprofile from "./User/MyProfile/Myprofile";
import Opimg from "./User/Opimg/Opimg";
import Photopage from "./User/Photopage/Photopage";
import UsereHelpandSupport from "./User/UserHelpandSupport/UserHelpandSupport";
import Videopage from "./User/Videopage/Videopage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./components/Login/Login";
import React, { useEffect, useLayoutEffect, useState } from "react";
import "./App.scss";
import ForgotPassWord from "./components/Forgot Password/ForgotPassWord";
import Register from "./components/Register/Register";
import Home from "./components/HomePage User/Home";
import VideosPage from "./components/HomePage User/VideosPage";
import SendOTP from "./components/Send OTP/SendOTP";
import Backhead from "./User/Headers/BackHead/Backhead";
import PreLogin from "./components/PreLoginStages/PreLogin";
import Wallet from "./User/Wallet/Walletpage";
import Rechargepage from "./User/Reachargepage/Rechargepage";
import Settingpage from "./User/Settingpage/Settingpage";
import { getMessaging, getToken } from "firebase/messaging";
import BottomBar from "./components/BottomBar/BottomBar";
import UserDM from "./User/UserDMpage/UserDM";
import ModalHomePage from "./components/ModalHomePage/ModalHomePage";
import ModalHelpSupport from "./components/ModalHelpSupport/ModalHelpSupport";
import Dmpage from "./component/DMpage/Dmpage";
import ModalSideUserDM from "./component/ModalSideUserDM/ModalSideUserDM";
import AddDM from "./component/DMpage/AddDMpage/AddDM";
import GiftMe from "./User/GiftMepage/GiftMe";
import SuperFanPage from "./User/SuperFan/SuperFanPage";
import MyPurchasePage from "./User/MyPurchase/MyPurchasePage";
import ShoutOutpage from "./User/ShoutOutpage/ShoutOutpage";
import UserLive from "./User/UserLive/UserLive";
import Shoutoutmsgpage from "./User/ShoutOutpage/ShoutOutmsgpage/Shoutoutmsgpage";
import ShoutoutmyMessages from "./User/ShoutOutpage/Shoutoutmymessages/ShoutoutmyMessages";
import UserVideoCall from "./User/UserVideoCall/UserVideoCall";
import SendRequestForVideocall from "./User/UserVideoCall/SendRequestForVideocall/SendRequestForVideocall";
import UserHelpandSupportDm from "./User/UserHelpandSupport/UserHelpandSupportDm";
import HandSDmPage from "./component/DMpage/HandSDmPage";
import ModalSideUserHelpAndSupportDm from "./component/ModalSideUserDM/ModalSideUserHelpAndSupportDm";
import LiveStrimingPage from "./User/UserLive/LiveStrimingPage";
import AudienceSideLive from "./User/UserLive/AudienceSideLive";
import IncomingVideoCallUserSide from "./User/UserVideoCall/userSideVideoCall/IncomingVideoCallUserSide";
import OneOnOneVideoCallScreen from "./User/UserVideoCall/userSideVideoCall/OneOnOneVideoCallScreen";
import Back_S_head from "./User/Headers/BackHead/Back_S_head";
import Model_Gift from "./Model-Side/Model_Gift/Model_Gift";
import Model_shoutout from "./Model-Side/Model_shoutout/Model_shoutout";
import UploadBanners from "./Model-Side/UploadBanners/UploadBanner";
import UploadBanner from "./Model-Side/UploadBanners/UploadBanner";
import Model_Update_gems from "./Model-Side/Model_Update_gems/Model_Update_gems";
import Model_Notification from "./Model-Side/Model_send_notification/Model_Notification";
import Model_Update_gems_balance from "./Model-Side/Model_Update_gems/Model_Update_gems_balance";
import Model_Home from "./Model-Side/Model_Home/Model_Home";
import AdminHead from "./User/Headers/MainHead/AdminHead";
import Model_live from "./Model-Side/Model_live/Model_live";
import Model_call from "./Model-Side/Model_call/Model_call";
import Model_Update_videocall_schedule from "./Model-Side/Model_call/Model_Update_videocall_schedule";
import ModelSendShoutoutVideo from "./Model-Side/Model_shoutout/ModelSendShoutoutVideo";
import UploadStory from "./Model-Side/Model_live/UploadStory";
import ModalLiveStrimingPage from "./Model-Side/Model_live/ModalLiveStrimingPage";
import ModalVideoCallPage from "./Model-Side/Model_call/ModalVideoCallPage";
import Modalpage from "./component/Firstpage/Modalpage";
import PaymentSucess from "./User/Reachargepage/PaymentSucess";
import PaymentFailed from "./User/Reachargepage/PaymentFailed";
import UpdatePhoto from "./Model-Side/Update_Photo/UpdatePhoto";
import Updatevideo from "./Model-Side/Update_Video/UpdateVideo";
import PaymentPage from "./User/Reachargepage/PaymentPage";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

function App() {
  document.addEventListener("contextmenu", (e) => {
    e.preventDefault();
  });
  const [width, height] = useWindowSize();
  if (!window.location.host.startsWith("www")) {
    window.location =
      window.location.protocol +
      "//" +
      "www." +
      window.location.host +
      window.location.pathname;
  }
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    const messaging = getMessaging();
    getToken(messaging, {
      vapidKey: process.env.REACT_APP_VAPIDKEY,
    })
      .then((currentToken) => {
        if (currentToken) {
          localStorage.setItem(
            process.env.REACT_APP_DEVICETOKEN_LOCALSTORAGE,
            JSON.stringify(currentToken)
          );
        } else {
        }
      })
      .catch((err) => {});
  };

  return (
    <>
      {width <= 475 ? (
        <BrowserRouter>
          <Routes>
            <Route path="/modalpage" element={<Modalpage />} />
            <Route
              path="/a/uploadphoto"
              element={[<Mainhead />, <Uploadphoto />, <BottomBar />]}
            />
            <Route
              path="/incomingcall"
              element={<IncomingVideoCallUserSide />}
            />
            <Route
              path="/oneononevideocallscreen"
              element={<OneOnOneVideoCallScreen />}
            />
            <Route
              path="/modalsideonetoonevideocall"
              element={<ModalVideoCallPage />}
            />
            <Route
              path="/modallivestriming"
              element={<ModalLiveStrimingPage />}
            />
            <Route
              path="/a/uploadvideo"
              element={[<Mainhead />, <Uploadvideo />, <BottomBar />]}
            />
            <Route
              path="/sendshoutoutvideo"
              element={[<Backhead />, <ModelSendShoutoutVideo />]}
            />
            <Route path="/" element={<Modalpage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/livestrmingpage" element={[<AudienceSideLive />]} />
            <Route path="/forgot-password" element={<ForgotPassWord />} />
            <Route path="/prelogin" element={<PreLogin />}>
              <Route path="/prelogin/register" element={<Register />} />
              <Route path="/prelogin/send-otp" element={<SendOTP />} />
            </Route>
            <Route path="/modalhelp&support" element={<ModalHelpSupport />} />
            <Route path="/userhomepage" element={[<Mainhead />, <BottomBar />]}>
              <Route
                path="/userhomepage/modalHomepage"
                element={<ModalHomePage />}
              />
              <Route path="/userhomepage/home" element={<Home />} />
              <Route path="/userhomepage/photos" element={<Photopage />} />
              <Route path="/userhomepage/videos" element={<Videopage />} />
            </Route>
            <Route path="/videos" element={<VideosPage />} />
            <Route path="/helpandsupport" element={<HelpandSupport />} />
            <Route
              path="/userdm"
              element={[<Mainhead />, <UserDM />, <BottomBar />]}
            />
            <Route
              path="/userhelpandsupport"
              element={[<MainBackhead />, <UserHelpandSupportDm />]}
            />

            <Route
              path="/modalsideuserlisting"
              element={[<Mainhead />, <Dmpage />, <BottomBar />]}
            />
            <Route
              path="/userlive"
              element={[<Mainhead />, <UserLive />, <BottomBar />]}
            />
            <Route
              path="/updatebalance"
              element={<Model_Update_gems_balance />}
            />

            <Route
              path="/a/Home"
              element={[<Mainhead />, <Model_Home />, <BottomBar />]}
            />
            <Route
              path="/a/live"
              element={[<Mainhead />, <Model_live />, <BottomBar />]}
            />

            <Route path="/a" element={<Adminheader />}>
              <Route
                path="/a/dm"
                element={[<Mainhead />, <Dmpage />, <BottomBar />]}
              />
            </Route>
            <Route path="/back-s" element={<Back_S_head />}>
              <Route
                path="/back-s/model_gift"
                element={[<Back_S_head />, <Model_Gift />]}
              />
              <Route
                path="/back-s/model_call"
                element={[<Back_S_head />, <Model_call />]}
              />
              <Route
                path="/back-s/update_video_call_schedule"
                element={[<Back_S_head />, <Model_Update_videocall_schedule />]}
              />
            </Route>
            <Route path="/mainback" element={<MainBackhead />}>
              <Route path="/mainback/success" element={<PaymentSucess />} />
              <Route
                path="/mainback/failed-recharge"
                element={<PaymentFailed />}
              />

              <Route path="/mainback/shoutout" element={<ShoutOutpage />} />
              <Route path="/mainback/myprofile" element={<Myprofile />} />
              <Route path="/mainback/paymentPage" element={<PaymentPage />} />
              <Route path="/mainback/rechargepage" element={<Rechargepage />} />
              <Route path="/mainback/setting" element={<Settingpage />} />
              <Route path="/mainback/giftme" element={<GiftMe />} />
              <Route path="/mainback/mypurchase" element={<MyPurchasePage />} />
              <Route
                path="/mainback/shoutoutmsg"
                element={<Shoutoutmsgpage />}
              />
              <Route
                path="/mainback/shoutoutmymessages"
                element={<ShoutoutmyMessages />}
              />
              <Route
                path="/mainback/uservideocall"
                element={<UserVideoCall />}
              />
              <Route
                path="/mainback/sendrequestforvideocall"
                element={<SendRequestForVideocall />}
              />
              <Route
                path="/mainback/userhelp"
                element={<UsereHelpandSupport />}
              />
            </Route>
            <Route
              path="/userlistingforhelpandsupport"
              element={[<Backhead />, <HandSDmPage />]}
            />
            <Route path="/back" element={<Backhead />}>
              <Route path="/back/model_shoutout" element={<Model_shoutout />} />
              <Route path="/back/uploadbanner" element={<UploadBanner />} />
              <Route path="/back/uploadstory" element={<UploadStory />} />
              <Route path="/back/updatephoto" element={<UpdatePhoto />} />
              <Route path="/back/updateVideo" element={<Updatevideo />} />
              <Route
                path="/back/upadetegems"
                element={[<Backhead />, <Model_Update_gems />]}
              />
              <Route
                path="/back/model_notification"
                element={[<Backhead />, <Model_Notification />]}
              />

              <Route path="/back/wallet" element={<Wallet />} />
              <Route path="/back/serchuser" element={<AddDM />} />
              <Route
                path="/back/modalsideuserchats"
                element={<ModalSideUserDM />}
              />
              <Route
                path="/back/modalsideuserhelpandsupportchats"
                element={<ModalSideUserHelpAndSupportDm />}
              />
              <Route path="/back/superfan" element={<SuperFanPage />} />
            </Route>
            <Route path="/helpandsupport" element={<HelpandSupport />} />
            <Route path="/opimg" element={<Opimg />} />
          </Routes>
        </BrowserRouter>
      ) : (
        <h3
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            position: "absolute",
            top: "50%",
            textAlign: "center",
          }}
        >
          {process.env.REACT_APP_TEXT_FOR_DESKTOP}
        </h3>
      )}
    </>
  );
}

export default App;
