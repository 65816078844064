import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@mui/material";
import React from "react";
import "./ModalComponent.scss";
import "../../App.scss";

const ModalComponent = (props) => {
  return (
    <Dialog
      open={props.open}
      onClose={() => props.close()}
      aria-labelledby="responsive-dialog-title"
      className="popup"
    >
      <DialogTitle className="dialog-title-background typography-loginpage">
        {props.title}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText
          className="typography-loginpage"
          style={{ marginBottom: "20px" }}
        >
          {props.text}
        </DialogContentText>
        <Button
          className="typography-loginpage button-color"
          onClick={() => props.close()}
        >
          {process.env.REACT_APP_MODAL_OKAY_BUTTON}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default ModalComponent;
