import { Box, Button, TextField, Typography } from '@mui/material'
import React from 'react'
import './Model_Notification.scss'
import '../../App.scss'

function Model_Notification() {
    let arr =[
        {
            msg1:"waiting to see you 😍",
            msg2:"Book your video call",
            date:"Oct 10,2022 01:12 PM"
        },
        {
            msg1:"test",
            msg2:"testing purpose",
            date:"Oct 10,2022 01:12 PM"
        },
        {
            msg1:"waiting to see you 😍",
            msg2:"Me in red ❤️❤️❤️",
            date:"Oct 10,2022 01:12 PM"
        },
        {
            msg1:"waiting to see you 😍",
            msg2:"Book your video call",
            date:"Oct 10,2022 01:12 PM"
        },
        {
            msg1:"waiting to see you 😍",
            msg2:"Book your video call",
            date:"Oct 10,2022 01:12 PM"
        },
    ]
  return (
    <Box className='m_notification'>
        <Box className='m_notification_header'>
        <Typography variant='h6' className='typography-loginpage m_f'>Notification Details</Typography>
        <TextField
                type="text"
                placeholder="Enter title"
                variant="outlined"
                focused={false}
                id='myprofile-form-field'
                fullWidth
                size="small"
              />
        <TextField
                type="text"
                placeholder="Enter Description"
                variant="outlined"
                focused={false}
                id='myprofile-form-field'
                fullWidth
                size="small"
              />
              <Button variant='contained' className='button_btn'>Send</Button>
              <Box className='m_notification_head_bac_color'>
              <Typography variant='body1' className='typography-loginpage m_f'>All Notifications</Typography>

              </Box>
    </Box>
    <Box className='m_notification_contant'>
        {arr.map((val)=>{
            return(
                <Box className='m_notification_contant_text_box contant_box'>
                                  <Typography variant='body2' className='typography-loginpage m_f'>{val.msg1}</Typography>
                                  <Typography variant='body2' className='typography-loginpage m_f'>{val.msg2}</Typography>
                                  <Typography variant='body2' className='typography-loginpage m_f'>{val.date}</Typography>

                </Box>
            )
        })}
    </Box>
    </Box>
  )
}

export default Model_Notification
