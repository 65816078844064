import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "../../firebaseConfig/firebaseConfig";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import Spinners from "../Spinner/Spinners";
import { useNavigate } from "react-router-dom";

const Shop = () => {
  const [pageData, setPageData] = useState({
    product: [],
    loading: true,
  });
const navigate = useNavigate()
  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    const q3 = query(collection(db, process.env.REACT_APP_PRODUCTS_COLLECTION_NAME), orderBy("createdAt", "desc"));
    getDocs(q3).then((result) => {
      const productInfo = [];
      result.forEach((doc) => {
        productInfo.push(doc.data());
      });
      setPageData({ product: productInfo, loading: false });
    });
  };
  const shoutout =()=>{
    navigate('/mainback/shoutout',{state: {muted: false}})
  }
  return <div style={{ minHeight: pageData.loading ? "25vh" : "auto" }}>{ 
    pageData.loading ? (
      <Spinners />
    ) : (
      <>
        {/* <Box className="shop">
          <Grid container className="shadow lock">
            <Grid item>
              <LockIcon id="lockicon" />
            </Grid>
            <Grid item>
              <Typography className="typography-loginpage lockicontext">
                Shop
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <div className="rec-carousel">
            <div className="rec-carousel-item">

            <div
              className="events carousel-item-set shopcss"
              itemsToShow={2.5}
              outerSpacing={0}
              pagination={false}
            >
              {pageData.product.map((ele) => {
                return <img src={ele.pImageUrl} />;
              })}
            </div>
            </div>
            </div>
          </Grid>
        </Box> */}
        <Box className="shoutOutButton">
          <Button onClick={()=>shoutout()}>
            <VolumeUpIcon />
            &nbsp;&nbsp;ShoutOuts
          </Button>
        </Box>
      </>
    )}</div>;
};

export default Shop;
