import React, { useEffect, useState } from "react";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "../../firebaseConfig/firebaseConfig";
import Spinners from "../Spinner/Spinners";
import { useNavigate } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Banner = () => {
  const navigate = useNavigate();
  const [pageData, setPageData] = useState({
    bannerInfo: [],
    loading: true,
  });

  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    const q2 = query(
      collection(db, process.env.REACT_APP_BANNER_INFO_COLLECTION_NAME),
      orderBy("uploadTime", "desc")
    );
    getDocs(q2).then((result) => {
      const banner = [];
      result.forEach((doc) => {
        banner.push(doc.data());
      });
      console.log("banner", banner);
      setPageData({ bannerInfo: banner, loading: false });
    });
  };

  return (
    <div>
      {pageData.loading ? (
        <Spinners />
      ) : (
        <div className="bannerSection">
          <Carousel>
            {pageData.bannerInfo.map((ele) => {
              return (
                <div
                  onClick={() => {
                    console.log("clicked");
                    ele?.bannerType == "image"
                      ? navigate("/userhomepage/photos")
                      : navigate("/userhomepage/videos");
                  }}
                >
                  <img
                    src={ele.bannerUrl}
                    // src="	https://imagedelivery.net/5QpcUF8IyjfhzLbp9BN2ng/e2f2c616-909e-4e92-e153-b1f6f6bea700/public"
                    style={{
                      border: "1px solid white",
                      height: "9em",
                      objectFit: "cover",
                    }}
                  />
                </div>
              );
            })}
          </Carousel>
        </div>
      )}{" "}
    </div>
  );
};

export default Banner;
