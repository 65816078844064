import React, { useEffect, useState } from "react";

const Timer = (props) => {
  const [isActive, setIsActive] = useState(false);
  const [isPaused, setIsPaused] = useState(true);
  const [time, setTime] = useState(0);

  useEffect(() => {
    let interval = null;

    interval = setInterval(() => {
      props.time(
        `${("0" + Math.floor((time / 3600000) % 24)).slice(-2)}:${(
          "0" + Math.floor((time / 60000) % 60)
        ).slice(-2)}:${("0" + Math.floor((time / 1000) % 60)).slice(-2)}`
      );
      setTime((time) => time + 10);
    }, 10);

    return () => {
      clearInterval(interval);
    };
  }, [isActive, isPaused]);
  return (
    <div className="timer">
      <span className="digits" style={{ fontFamily: "var(-font)" }}>
        {("0" + Math.floor((time / 3600000) % 24)).slice(-2)}:
      </span>
      <span className="digits" style={{ fontFamily: "var(-font)" }}>
        {("0" + Math.floor((time / 60000) % 60)).slice(-2)}:
      </span>
      <span className="digits" style={{ fontFamily: "var(-font)" }}>
        {("0" + Math.floor((time / 1000) % 60)).slice(-2)}
      </span>
    </div>
  );
};

export default Timer;
