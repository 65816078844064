import React, { useEffect, useState } from "react";
import "./UserVideoCall.scss";
import { useLocation, useNavigate } from "react-router-dom";
import icLogin from "../../assests/login.jpg";
import { Box, Button, IconButton, Tab, Tabs, Typography } from "@mui/material";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import { TabPanel } from "../../components/Login/Tabelpanel";
import { getAuth } from "firebase/auth";
import Book from "./Book";
import VideoCompleted from "./VideoCompleted";
import VideoPending from "./VideoPending";
import {
  collection,
  getDocs,
  limit,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebaseConfig/firebaseConfig";
import ModalComponent from "../../components/ModalComponent/ModalComponent";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function UserVideoCall() {
  const location = useLocation();
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem(process.env.REACT_APP_USERS_LOCALSTORAGE))
  );

  const [muted, setMuted] = useState(true);
  const [count, setCount] = useState(0);
  const [values, setValues] = useState(0);
  const [videoCallRequestObject, setVideoCallRequestObject] = useState({});
  const [videocallModalopen, setVideoCallModalOpen] = useState(false);
  const [text, setText] = useState("");
  const navigate = useNavigate();
  const route = useLocation();
  const auth = getAuth();
  useEffect(() => {
    if (location) {
      setMuted(location.state.muted);
    }
  }, []);
  const handleClose = () => {
    setVideoCallModalOpen(false);
  };

  const getData = () => {
    let data = {};
    let q1 = query(
      collection(db, process.env.REACT_APP_CALL_COLLECTION_NAME),
      where("user_id", "==", user.idUser),
      limit(1)
    );
    getDocs(q1).then((result) => {
      result.forEach((doc) => {
        setVideoCallRequestObject({ ...doc.data(), docId: doc.id });
        data = { ...doc.data(), docId: doc.id };
        console.log(data);
      });
      if (Object.keys(data).length > 0) {
        if (data?.has_user_join || data?.call_end || data?.rejected_by_user) {
          setVideoCallModalOpen(true);
          setText(process.env.REACT_APP_NO_INCOMING_VIDEO_CALL_YET_TEXT);
        }
      } else {
        setVideoCallModalOpen(true);
        setText(process.env.REACT_APP_NO_INCOMING_VIDEO_CALL_YET_TEXT);
      }
    });
  };

  if (
    Object.keys(videoCallRequestObject).length > 0 &&
    !videoCallRequestObject?.has_user_join &&
    !videoCallRequestObject?.call_end &&
    !videoCallRequestObject?.rejected_by_user
  ) {
    navigate("/incomingcall", {
      state: { videocallobject: videoCallRequestObject },
    });
  }

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const handleChange = (event, newValue) => {
    setValues(newValue);
  };
  return (
    <Box className="videocall" style={{ backgroundColor: "var(--black)" }}>
      <Box>
        {/* <video
          src={on1vdocall}
          autoplay="true"
          loop
          muted={muted}
          playsInline={true}
          style={{ maxWidth: "100vw" }}
        ></video> */}
        <img src={icLogin} style={{ maxWidth: "100vw" }} />
      </Box>
      <Box>
        <Typography variant="h6" className="videoheading">
          {
            process.env
              .REACT_APP_SENT_REQUEST_FOR_VIDEO_CALL_CONFIRM_ONE_ON_ONE_CALL_TEXT
          }
        </Typography>
      </Box>
      <Box className="line" />
      <Box>
        <Box className="shoutOutButton">
          <Button
            onClick={() => {
              getData();
            }}
          >
            {process.env.REACT_APP_VIEW_INCOMING_VIDEO_CALL_BUTTON}
          </Button>
        </Box>
        <Box className="tab">
          <Tabs
            value={values}
            onChange={handleChange}
            className="tab-menu "
            aria-label="basic tabs example"
          >
            <Tab
              className="typography-loginpage"
              label="Book"
              {...a11yProps(0)}
            />
            <Tab
              className="typography-loginpage"
              label="Pending"
              {...a11yProps(1)}
            />
            <Tab
              className="typography-loginpage"
              label="Completed"
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <Box className="tabpanel ll">
          <TabPanel value={values} index={0} className="a">
            <Book />
          </TabPanel>
          <TabPanel value={values} index={1} className="a">
            <VideoPending />
          </TabPanel>
          <TabPanel value={values} index={2} className="a">
            <VideoCompleted />
          </TabPanel>
        </Box>
      </Box>
      <ModalComponent
        open={videocallModalopen}
        close={() => handleClose()}
        text={text}
        title={process.env.REACT_APP_DIALOG_APP_NAME}
      />

      <ToastContainer
        closeButton={false}
        theme="dark"
        autoClose={3000}
        hideProgressBar={true}
        position="top-center"
        closeOnClick={true}
      />
    </Box>
  );
}

export default UserVideoCall;
