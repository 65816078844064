import React, { useState } from "react";
import profile_feed from "../../../assests/profile_feed.png";
import gridview_icon from "../../../assests/gridview_icon.png";
import filter_icon from "../../../assests/filter_icon.png";
import {
  Box,
  Dialog,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  Typography,
} from "@mui/material";
import "./HomeFeed.scss";
import Photos from "./Photos";
import Videos from "./Videos";

function HomeFeed() {
  const [page, setPage] = useState("photo");
  const [view, setView] = useState("grid");
  const [open, setOpen] = useState(false);

  return (
    <Box>
      <Box className="home_feed_head">
        <img
          alt=""
          src={profile_feed}
          className="home_feed_img"
          onClick={() => setView("profile")}
        />
        <img
          alt=""
          src={gridview_icon}
          className="home_feed_img"
          onClick={() => setView("grid")}
        />
        <img
          alt=""
          src={filter_icon}
          className="home_feed_img"
          onClick={() => setOpen(!open)}
        />
      </Box>
      <Box className="line" />

      <Box sx={{ textAlign: "center" }}>
        <h3 variant="body1" className="typography-loginpage feed">
          {page === "photo" ? " Photos" : "Videos"}
        </h3>
      </Box>
      <Box className="home_feed">
        <Photos view={view} currentPage={page} />
        <Videos view={view} currentPage={page} />
      </Box>

      <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        <DialogTitle
          style={{
            backgroundColor: "var(--pink)",
            color: "var(--white)",
            padding: "16px 14px",
          }}
        >
          <Typography variant="body1" style={{ color: "var(--white)" }}>
            {process.env.REACT_APP_NAME}
          </Typography>
          <Typography variant="body2">Select media type</Typography>
        </DialogTitle>
        <Divider />

        <List sx={{ pt: 0 }}>
          <ListItem disableGutters>
            <ListItemButton
              onClick={() => {
                setPage("photo");
                setOpen(!open);
              }}
            >
              <ListItemAvatar>Photo</ListItemAvatar>
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem disableGutters>
            <ListItemButton
              onClick={() => {
                setPage("video");
                setOpen(!open);
              }}
            >
              <ListItemAvatar>Video </ListItemAvatar>
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem disableGutters>
            <ListItemButton onClick={() => setOpen(!open)}>
              <ListItemAvatar>Cancel </ListItemAvatar>
            </ListItemButton>
          </ListItem>
        </List>
      </Dialog>
    </Box>
  );
}

export default HomeFeed;
