import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import MessageIcon from "@mui/icons-material/Message";
import FavoriteIcon from "@mui/icons-material/Favorite";
import "./HomeFeed.scss";
import LockIcon from "@mui/icons-material/Lock";
import ModelProfilePicture from "../../../assests/incomingcall.jpg";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";

function Grid_view({ data, paginate, lastDoc }) {
  const navigate = useNavigate();
  return (
    <Box id="scrollListGrid" style={{ height: "100%", overflowY: "scroll" }}>
      <InfiniteScroll
        dataLength={Object.keys(data).length}
        next={() => {
          paginate(lastDoc);
        }}
        scrollableTarget="scrollListGrid"
        hasMore={true}
        useWindow={false}
      >
        <Box className="Grid_view">
          {Object.values(data).map((item, index) => {
            return (
              <Box
                className="img_box"
                key={index}
                onClick={() =>
                  navigate("/back/updatephoto", {
                    state: { photoobject: item },
                  })
                }
              >
                <img alt="picture" src={item.photoUrl} className="grid_img" />
                <Box className="img_text_box">
                  <Typography
                    variant="body2"
                    className="typography-loginpage text"
                  >
                    <FavoriteIcon className="img_text" />
                    <span className="img_text_span">{item.likedCounter}</span>
                  </Typography>
                  <Typography
                    variant="body2"
                    className="typography-loginpage text"
                  >
                    <MessageIcon className="img_text" />
                    <span className="img_text_span">{item.commentCounter}</span>
                  </Typography>
                </Box>
                {item.noOfGems != 0 ? (
                  <Typography
                    variant="body1"
                    className="typography-loginpage paid_text"
                  >
                    <LockIcon
                      style={{
                        fontSize: "18px",
                        marginRight: "4px",
                      }}
                    />
                    <span className="lock_text">Paid Image </span>
                  </Typography>
                ) : null}
              </Box>
            );
          })}
        </Box>
      </InfiniteScroll>
    </Box>
  );
}

export default Grid_view;
/*
<>
                    {arr.map((val) => {
                        return (
                            <Box className='video_box'>
                                <img alt='' src={val.url} className='grid_video' />
                                <Box className="img_text_box">
                                    <Typography variant='body2' className='typography-loginpage text'>
                                        <FavoriteIcon className='img_text' />
                                        0
                                    </Typography>
                                    <Typography variant='body2' className='typography-loginpage text'>
                                        <MessageIcon className='img_text' />
                                        0
                                    </Typography>
                                </Box>
                                {val.id === "paid" ?

                                <Typography variant='body2' className='typography-loginpage paid_text'>
                                    Paid Video
                                </Typography>
                                :""}
                            </Box>
                        )
                    })

                    }
                </>

*/
