import React, { useEffect, useRef, useState } from "react";
import "./IncomingVideoCallUserSide.scss";
import {
  deleteDoc,
  doc,
  onSnapshot,
  query,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../../firebaseConfig/firebaseConfig";
import { useNavigate } from "react-router-dom";
import CallEndIcon from "@mui/icons-material/CallEnd";
import MicIcon from "@mui/icons-material/Mic";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import FlipCameraIosIcon from "@mui/icons-material/FlipCameraIos";
import { MicOff } from "@mui/icons-material";
import { toast } from "react-toastify";

export const VideoPlayer = ({
  user,
  index,
  videocallobject,
  control,
  switchCamera,
  navigation,
}) => {
  const ref = useRef();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [mute, setMute] = useState(false);
  const [videoCallData, setVideoCallData] = useState({});

  useEffect(() => {
    user.videoTrack.play(ref.current);
    getData();
  }, []);


  const notify = () => toast(process.env.REACT_APP_VIDEO_CALL_END_TOAST_TEXT,{toastId: 1});


  const getData = () => {
    const q1 = query(doc(db,process.env.REACT_APP_CALL_COLLECTION_NAME, videocallobject?.docId));
    onSnapshot(q1, (querySnapshot) => {
      setVideoCallData({ ...querySnapshot.data() });
    });
  };
  if (videoCallData?.call_end || videoCallData?.rejected_by_user) {
    notify();
    navigation();
  }
  const handleCallReject = () => {
    updateDoc(doc(db, process.env.REACT_APP_CALL_COLLECTION_NAME, videocallobject?.docId), {
      call_end: true,
    }).then((result) => {
      setOpen(false);
      navigation();
      notify();
      deleteDoc(doc(db,process.env.REACT_APP_CALL_COLLECTION_NAME, result.id));
    });
  };

  const handleMic = () => {
    control();
    setMute(!mute);
  };

  const handleCameraFlip = () => {
    switchCamera();
  };


  return (
    <div>
      {index === 0 && (
        <div
          ref={ref}
          style={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            width: "100vw",
            height: "100vh",
            left: "0px",
            top: "0px",
          }}
        ></div>
      )}
      {index !== 0 && (
        <div
          ref={ref}
          style={{
            width: "40vw",
            height: "40vh",
            position: "fixed",
            top: "10px",
            left: "10px",
            zIndex: "200",
          }}
          muted
        ></div>
      )}
      {index === 0 && (
        <Box
          style={{
            zIndex: "5000",
            position: "fixed",
            height: "100px",
            bottom: "30px",
            width: "100vw",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            left: "0px",
          }}
        >
          {!mute ? (
            <MicIcon
              className="call-icon input-field-shadow"
              style={{
                backgroundColor: "var(--white)",
                padding: "10px",
                borderRadius: "50%",
                color: "var(--blueLight)",
              }}
              onClick={() => handleMic()}
            />
          ) : (
            <MicOff
              className="call-icon input-field-shadow"
              style={{
                backgroundColor: "var(--blueLight)",
                padding: "10px",
                borderRadius: "50%",
                color: "var(--white)",
              }}
              onClick={() => handleMic()}
            />
          )}
          <FlipCameraIosIcon
            className="call-icon input-field-shadow"
            style={{
              backgroundColor: "var(--white)",
              padding: "10px",
              color: "var(--blueLight)",
              borderRadius: "50%",
            }}
            onClick={() => handleCameraFlip()}
          />
          <CallEndIcon
            className="call-icon input-field-shadow"
            style={{
              backgroundColor: "var(--red)",
              padding: "10px",
              borderRadius: "50%",
            }}
            onClick={() => setOpen(true)}
          />
        </Box>
      )}
      {open && (
        <Dialog
          open={open}
          onClose={()=>setOpen(false)}
          aria-labelledby="responsive-dialog-title "
          className="loguotpop"
          
        >
          <DialogTitle className="dialog-title-background typography-loginpage">
            {process.env.REACT_APP_DIALOG_APP_NAME}
          </DialogTitle>

          <DialogContent>
            <DialogContentText className="typography-loginpage">
              {process.env.REACT_APP_EXIT_CALL_MODAL_TEXT}
            </DialogContentText>
            <Button
              className="typography-loginpage loginpage-button"
              onClick={() => handleCallReject()}
            >
              Yes
            </Button>
            <Button
              className="typography-loginpage loginpage-button"
              onClick={() => setOpen(false)}
            >
              No
            </Button>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};
