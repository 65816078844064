import { Box, Button, Dialog, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import './Model_shoutout.scss'
import { addDoc, collection, deleteDoc, doc, getDocs, orderBy, query } from 'firebase/firestore';
import { db } from '../../firebaseConfig/firebaseConfig';
import { useNavigate } from 'react-router-dom';
import Spinners from "../../components/Spinner/Spinners";

function Model_shoutout_Pending() {
  const [shoutOutPending, setShoutOutPending] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [onShoutoutreject, setOnShoutoutreject] = useState({open: false, val:"", index: null});
  const [count, setCount] = useState(0);
  const navigate = useNavigate();
  useEffect(()=>{
    getCompletedShotout();
  },[count])

  const getCompletedShotout = () =>{
    setIsLoading(true);

    let q1 = query(collection(db,process.env.REACT_APP_PENDING_SHOUTOUT_REQUEST_COLLECTION),orderBy("requestedOn","desc"));
    getDocs(q1).then((pendingShoutOut)=> {
      let allShoutOuts = [];
      pendingShoutOut.forEach((shoutout)=>{
        allShoutOuts.push({...shoutout.data(),docId: shoutout.id});
      })
      setIsLoading(false);
      setShoutOutPending(allShoutOuts);
    })
  }

  const handleShoutoutUpload = (val) =>{
    navigate("/sendshoutoutvideo",{state:{val:val}})
  }

  const handleShoutOutReject = (val) =>{
    
     let rejectShoutOut = {
      message: val.message,
      noOfGems: val.noOfGems,
      requestedBy: val.requestedBy,
      requestedOn: val.requestedOn,
      tnc: val.tnc,
      uid: val.uid,
      userEmail: val.userEmail,
      userImage: val.userImage,
      public: val.public
     }
     addDoc(collection(db, process.env.REACT_APP_REJECTED_SHOUTOUT_REQUEST_COLLECTION),rejectShoutOut).then(()=>{
      deleteDoc(doc(db,process.env.REACT_APP_PENDING_SHOUTOUT_REQUEST_COLLECTION, val.docId)).then(()=>{
        setCount(count + 1);
        setOnShoutoutreject({open: false, index: null, val:""})
      })
     })
     
  }

  return (
    <Box sx={{padding:"0px",display:"flex", flexDirection:"column", justifyContent:"center",alignItems:"center", height:"fit-content"}}>
       {isLoading ? <Spinners /> : null}
        {shoutOutPending.map((val,index)=>{
                        const dateTimeString2 = `${val.requestedOn.toDate().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit",hour12:true})} ${new Date(val.requestedOn.toDate()).toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' })}` 

            return(
                <Box className="contant_box m_model_sh_cop_box" >
                 <Typography variant='body1' className='typography-loginpage'>{process.env.REACT_APP_REQUESTED_BY_TEXT} {val.requestedBy}</Typography>
                 <Typography variant='body1' className='typography-loginpage'>{process.env.REACT_APP_REQUESTED_ON_TEXT} {dateTimeString2}</Typography>
                 <Typography variant='body1' className='typography-loginpage'>{process.env.REACT_APP_MESSAGE_TEXT}:</Typography>
                 <Typography variant='body2' className='typography-loginpage'style={{textShadow:'none'}}>{val.message}</Typography>
                 <Button variant='contained' className='typography-loginpage m_model_sh_cop_btn' onClick={()=>handleShoutoutUpload(val)}>{process.env.REACT_APP_POST_VIDEO_COMPLETED_BUTTON}</Button>
                 <Button variant='contained' className='typography-loginpage m_model_sh_cop_btn reject' onClick={()=>setOnShoutoutreject({open:true,val:val,index:index})}>{process.env.REACT_APP_REJECT_BUTTON}</Button>
                </Box>
            )
        })}
         {onShoutoutreject.open && <Dialog open={onShoutoutreject.open} aria-labelledby="responsive-dialog-title " className="loguotpop" style={{backgroundColor:'var(--pinkDark)'}}> 
        <DialogTitle className="dialog-title-background typography-loginpage">
          {process.env.REACT_APP_DIALOG_APP_NAME}
        </DialogTitle>
      
        <DialogContent>
          <DialogContentText  className="typography-loginpage" >
            {process.env.REACT_APP_SHOUTOUT_REJECT_MODAL_TEXT} {onShoutoutreject.val.requestedBy}?
          </DialogContentText>
          <Button className="typography-loginpage loginpage-button"  onClick={()=> handleShoutOutReject(onShoutoutreject.val)}>{process.env.REACT_APP_YES_BUTTON1}</Button>
          <Button className="typography-loginpage loginpage-button" onClick={() => setOnShoutoutreject({open: false, val:"", index:null})}>{process.env.REACT_APP_NO_BUTTON}</Button>
        </DialogContent>
      </Dialog>}
       </Box>
  )
}

export default Model_shoutout_Pending
