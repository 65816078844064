import React, { useState } from "react";
import "../../User/UserVideoCall/SendRequestForVideocall/SendRequestForVideocall.scss";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { MobileTimePicker } from "@mui/x-date-pickers";
import "../../App.scss";
import "./Model_call.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebaseConfig/firebaseConfig";

function Model_Update_videocall_schedule() {
  const navigate = useNavigate();
  const location = useLocation();
  const [opencal, setOpencal] = useState(false);
  const [data, setData] = useState({ date: dayjs(), time: dayjs() });
  const [sh, setSh] = useState(false);
  const updateCall = location.state.updateCallTimings;
  console.log("updateCall", updateCall);

  const handleUpdateVideoCallTimings = () => {
    let UpdatedTimings = {
      date: data.date.format("MMMM DD,YYYY"),
      time: data.time.format("hh:mm A"),
    };
    console.log("data", UpdatedTimings);
    updateDoc(
      doc(
        db,
        process.env.REACT_APP_VIDEO_CALL_REQUEST_COLLECTION_NAME,
        updateCall.pendingCallDocId
      ),
      { ...UpdatedTimings }
    ).then(() => navigate("/back-s/model_call"));
  };

  return (
    <Box className="videocall_schedule">
      <Typography variant="body1" className="typography-loginpage">
        Update video call schedule
      </Typography>
      <Box className="line" />
      <Box className="videocall_schedule_contant">
        <Typography variant="body2" className="typography-loginpage">
          Update Video Call Date and Time.Choose date and time to confirm the
          appoinment for a video call
        </Typography>
        <Typography variant="body2" className="typography-loginpage">
          Tap on date/time to select the date and time
        </Typography>
        <Typography variant="body2" className="typography-loginpage">
          Select Date
        </Typography>
        <Button
          variant="contained"
          className="srvbtn"
          onClick={() => setOpencal(!opencal)}
        >
          {data.date.format("MMM DD,YYYY")}
        </Button>
        {opencal === true ? (
          <LocalizationProvider dateAdapter={AdapterDayjs} id="caldiv">
            <DemoItem>
              <Box className="calhead">
                <Typography variant="body1">
                  {data.date.format("YYYY")}
                </Typography>
                <Typography
                  variant="h4"
                  className="typ-chekgroupsrv"
                  style={{ textAlign: "left" }}
                >
                  {data.date.format("ddd")},{data.date.format("MMM")}
                  {data.date.format("DD")}
                </Typography>
              </Box>
              <DateCalendar
                defaultValue={dayjs()}
                disablePast={true}
                onChange={(e) => {
                  setData({ ...data, date: dayjs(e) });
                }}
              />
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button variant="text" onClick={() => setOpencal(!opencal)}>
                  CANCEL
                </Button>
                <Button
                  variant="text"
                  onClick={() => {
                    setOpencal(!opencal);
                  }}
                >
                  OK
                </Button>
              </Box>
            </DemoItem>
          </LocalizationProvider>
        ) : (
          ""
        )}
        <Typography variant="body2" className="typography-loginpage">
          Select Time
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MobileTimePicker
            defaultValue={dayjs()}
            onChange={(e) => {
              setData({ ...data, time: dayjs(e) });
            }}
            sx={{
              width: "110px",
              borderColor: "none",
              marginBottom: "15px",
              boxShadow: "0px 0px 10px 0.1px var(--black)",
            }}
          />
        </LocalizationProvider>

        <Button
          variant="contained"
          className="srvbtn"
          onClick={() => handleUpdateVideoCallTimings()}
          style={{ textTransform: "none" }}
        >
          Confirm
        </Button>
      </Box>
    </Box>
  );
}

export default Model_Update_videocall_schedule;
