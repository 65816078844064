import React, { useState } from 'react'
import { TabPanel } from '../../components/Login/Tabelpanel'
import { Box, Tab, Tabs } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import '../Model_Home/Model_Home.scss'
import Model_call_pending from './Model_call_pending';
import Model_call_completed from './Model_call_completed';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

function Model_call() {
    const navigate = useNavigate();
    const [values, setValues] = useState(0);
    const [alignment, setAlignment] = useState(true);

    const handleAlignment = () => {
        setAlignment(!alignment);
    };
    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    };
    
    
    const handleChange = (event, newValue) => {
        setValues(newValue);
    };

    
  return (
    <>
       <Box className="m_home">
          
            <Box className="m_home_tab">
                <Tabs
                    value={values}
                    onChange={handleChange}
                    className="tab-menu "
                    aria-label="basic tabs example"
                >
                    <Tab
                        className="typography-loginpage"
                        label="Pending"
                        {...a11yProps(0)}
                    />
                    <Tab
                        className="typography-loginpage"
                        label="Completed"
                        {...a11yProps(1)}
                    />
                </Tabs>
               </Box>
                <Box className="tabpanels">
                    <TabPanel value={values} index={0} className="a">
                        <Model_call_pending/>
                    </TabPanel>
                    <TabPanel value={values} index={1} className="a">
                        <Model_call_completed/>
                    </TabPanel>

                </Box>
        </Box>
        <ToastContainer
            closeButton={false}
            theme="dark"
            autoClose={3000}
            hideProgressBar={true}
            position="top-center"
            closeOnClick={true}
          />
    </>
  )
}

export default Model_call
