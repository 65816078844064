import React from 'react'
import './Model_gift.scss'
import '../../App.scss'
import { Box, Typography } from '@mui/material'

function Model_Gift() {
    let arr =[
        {   index:"1",
            heading:"pramesh wants to send you a gift",
            EmailID:"prameshkasana@gamil.com",
            Contact:"+918447383016",
            Time:"09:04 am - Apr 28,2023"
    },
        {   index:"2",
            heading:"Ammy wants to send you a gift",
            EmailID:"arshdeepsingh2028@gmail.com",
            Contact:"+918447383016",
            Time:"09:04 am - Apr 28,2023"
    },
        {   index:"3",
            heading:"Ruban Mathew wants to send you a gift",
            EmailID:"prameshkasana@gamil.com",
            Contact:"+918447383016",
            Time:"09:04 am - Apr 28,2023"
    },
        {   index:"4",
            heading:"pramesh wants to send you a gift",
            EmailID:"prameshkasana@gmail.com",
            Contact:"+918447383016",
            Time:"09:04 am - Apr 28,2023"
    },
        {   index:"5",
            heading:"pramesh wants to send you a gift",
            EmailID:"prameshkasana@gmail.com",
            Contact:"+918447383016",
            Time:"09:04 am - Apr 28,2023"
    },
        {   index:"6",
            heading:"pramesh wants to send you a gift",
            EmailID:"prameshkasana@gmail.com",
            Contact:"+918447383016",
            Time:"09:04 am - Apr 28,2023"
    },
        {   index:"7",
            heading:"pramesh wants to send you a gift",
            EmailID:"prameshkasana@gmail.com",
            Contact:"+918447383016",
            Time:"09:04 am - Apr 28,2023"
    },
]
  return (
    <Box className='m_gift'>
        <Box>
      <Box className='m_gift_header'>
        <Typography variant='h6' className='typography-loginpage m_gift_head'>Gift List</Typography>
        <Typography variant='body2' className='typography-loginpage m_gift_head2'>(onLongPress you can copy EmailID)</Typography>
        <Box className='line'/>

      </Box>
      </Box>

      <Box className='m_gift_contant '>
        {arr.map((val)=>{
            return(
                <Box className="contant_box m_c_box">
                       <Typography variant='body2' className='typography-loginpage m_gift_text' style={{marginBottom:'5px'}}>{val.index}) {val.heading}</Typography>
                       <Box className='line' sx={{marginBottom:"8px"}}/>
                       <Typography variant='body2' className='typography-loginpage m_gift_text'>EmailID: {val.EmailID}</Typography>
                       <Typography variant='body2' className='typography-loginpage m_gift_text'>Contact Number: {val.Contact}</Typography>
                       <Typography variant='body2' className='typography-loginpage m_gift_text'>Time: {val.Time}</Typography>

        </Box>
            )
        })}
        
      </Box>
    </Box>
  )
}

export default Model_Gift
