import React from "react";

const Spinner2 = () => {
  return (
    <div
      className="spinnerDiv"
      style={{
        position: "fixed",
        top: "-60px",
        height: "100vh",
        // width: 100vw;
        width: "100vw",
        right: "-23px",
        zIndex: "5005",
      }}
    >
      <div
        class="lds-spinner"
        style={{
          top: "63%",
          left: "45%",
          position: "absolute",
          zIndex: "5005",
          background: "var(--themeCustomColor)",
          borderRadius: "10px",
          transform: "translate(-50%, -50%)",
        }}
      >
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

export default Spinner2;
