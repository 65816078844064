import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import "./Uploadphoto.scss";
import jems from "../Images/jems.png";
import ModalComponent from "../../components/ModalComponent/ModalComponent";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from "../../firebaseConfig/firebaseConfig";
import axios from "axios";
import Spinners from "../../components/Spinner/Spinners";
import Spinner2 from "../../components/Spinner/Spinner2";

function Uploadphoto() {
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [text, setText] = useState("");
  const [file, setFile] = useState(null);
  const [photoDescription, setPhotoDescription] = useState({
    photoUrl: "",
    title: "",
    desc: "",
    noOfGems: "",
  });
  const fileUpload = useRef();

  const handlePhotoPublish = () => {
    if (photoDescription.photoUrl == "") {
      setText(process.env.REACT_APP_MODAL_TEXT_UPLOADPHOTO);
      setOpen(true);
    } else if (photoDescription.title.trim() == "") {
      setText(process.env.REACT_APP_MODAL_TEXT_ENTER_TITLE);
      setOpen(true);
    } else if (photoDescription.desc.trim() == "") {
      setText(process.env.REACT_APP_MODAL_TEXT_ENTER_DISCRIPTION);
      setOpen(true);
    } else if (photoDescription.noOfGems == null) {
      setText(process.env.REACT_APP_MODAL_TEXT_ENTER_GEMS);
      setOpen(true);
    } else {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("file", file);
      let axiosConfig = {
        headers: {
          "X-Auth-Email": process.env.REACT_APP_X_AUTH_EMAIL,
          "X-Auth-Key": process.env.REACT_APP_X_AUTH_KEY,
          Authorization: process.env.REACT_APP_AUTHORIZATION,
          "Content-Type": process.env.REACT_APP_CONTENT_TYPE,
          "Access-Control-Allow-Origin":
            process.env.REACT_APP_ACCESS_CONTROL_ALLOW_ORIGIN,
          Accept: process.env.REACT_APP_ACCEPT,
        },
      };
      axios
        .post(
          process.env.REACT_APP_IMAGE_UPLOAD_CLOUDFAIR_URL,
          { file: file },
          axiosConfig
        )
        .then((response) => {
          if (response.data.success) {
            let imgID = response.data.result.id;
            let imagePath = `${process.env.REACT_APP_IMAGE_GET_CLOUDFAIR_URL1}${imgID}${process.env.REACT_APP_IMAGE_GET_CLOUDFAIR_URL2}`;
            addDoc(
              collection(db, process.env.REACT_APP_PHOTO_UPLOAD_COLLECTION),
              {
                ...photoDescription,
                noOfGems: parseInt(photoDescription.noOfGems),
                createdAt: serverTimestamp(),
                likeCounter: parseInt(
                  process.env.REACT_APP_UPLOAD_PHOTO_LIKE_COUNTER
                ),
                monitizeState:
                  process.env.REACT_APP_UPLOAD_PHOTO_MONITIZE_STATE,
                photoUrl: imagePath,
              }
            ).then(() => {
              setIsLoading(false);
              setOpen(true);
              setText(process.env.REACT_APP_PHOTO_UPLOAD_SUCESSFULY_TEXT);
            });
          }
        });
    }
  };

  const close = () => {
    setPhotoDescription({
      photoUrl: "",
      title: "",
      desc: "",
      noOfGems: "",
    });
    setFile(null);
    setOpen(false);
  };

  const preview = (e) => {
    setFile(e.target.files[0]);
    setPhotoDescription({
      ...photoDescription,
      photoUrl: URL.createObjectURL(e.target.files[0]),
    });
  };
  return (
    <>
      {isLoading ? <Spinner2 /> : null}
      <Box className="upmain">
        <Box>
          <Typography variant="h6" id="updiv">
            {process.env.REACT_APP_UPLOAD_PHOTO_PAGE_HEADING}
          </Typography>
        </Box>
        <Box id="line" />
        <Box className="btndiv">
          <Button
            variant="contained"
            id="btn"
            onClick={() => fileUpload.current.click()}
          >
            {process.env.REACT_APP_GALLARY_BUTTON_TEXT}
            <input
              hidden
              accept="image/*"
              ref={fileUpload}
              multiple
              type="file"
              onChange={(e) => preview(e)}
            />
          </Button>
        </Box>
        <Box id="line" />
        <Box className="formdiv">
          <Typography variant="h6" id="formheadig">
            {process.env.REACT_APP_UPLOAD_PHOTO_MEDIA_DETAILS}
          </Typography>
          <Box className="formimgdiv">
            {photoDescription.photoUrl == "" ? null : (
              <Box className="boximg">
                <img alt="" src={photoDescription.photoUrl} className="img" />
              </Box>
            )}

            {photoDescription.photoUrl == "" ? (
              <Typography variant="body1" id="formimgheadig">
                {process.env.REACT_APP_NO_PHOTO_SELECTED_TEXT}
              </Typography>
            ) : null}
          </Box>
          <Box>
            <Grid container className="box" columnSpacing={{ xs: 1 }}>
              <Grid item xs={4}>
                <Typography variant="body1" id="formtext">
                  {process.env.REACT_APP_TITLE_TEXT}
                </Typography>
              </Grid>
              <Grid item xs={8} className="upinput">
                <TextField
                  type="text"
                  placeholder={
                    process.env.REACT_APP_PLACEHOLDER_TEXT_ENTER_TITLE
                  }
                  variant="outlined"
                  focused={false}
                  id="textfild"
                  value={photoDescription.title}
                  onChange={(e) =>
                    setPhotoDescription({
                      ...photoDescription,
                      title: e.target.value,
                    })
                  }
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container className="box" columnSpacing={{ xs: 1 }}>
              <Grid item xs={4}>
                <Typography variant="body2" id="formtext">
                  {process.env.REACT_APP_DISCRIPTION_TEXT}
                </Typography>
              </Grid>
              <Grid item xs={8} className="upinput2">
                <TextField
                  type="text"
                  placeholder={
                    process.env.REACT_APP_PLACEHOLDER_TEXT_ENTER_DISCRIPTION
                  }
                  variant="outlined"
                  focused={false}
                  id="textfild"
                  value={photoDescription.desc}
                  onChange={(e) =>
                    setPhotoDescription({
                      ...photoDescription,
                      desc: e.target.value,
                    })
                  }
                  fullWidth
                  style={{ padding: 0 }}
                  multiline
                  rows={3}
                />
              </Grid>
            </Grid>
            <Grid container className="box" columnSpacing={{ xs: 1 }}>
              <Grid item xs={4}>
                <img alt="" src={jems} className="formimg" />
              </Grid>
              <Grid item xs={8} className="upinput">
                <TextField
                  type="text"
                  placeholder={
                    process.env.REACT_APP_PLACEHOLDER_TEXT_ENTER_GEMS
                  }
                  variant="outlined"
                  value={photoDescription.noOfGems}
                  onChange={(e) =>
                    setPhotoDescription({
                      ...photoDescription,
                      noOfGems: e.target.value,
                    })
                  }
                  focused={false}
                  id="textfild"
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container className="box" columnSpacing={{ xs: 1 }}>
              <Grid item xs={4}></Grid>
              <Grid item xs={8}>
                <Button
                  variant="contained"
                  id="publishbtn"
                  onClick={() => handlePhotoPublish()}
                >
                  {process.env.REACT_APP_PUBLISH_BUTTON_TEXT}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <ModalComponent
        close={close}
        open={open}
        text={text}
        title={process.env.REACT_APP_DIALOG_APP_NAME}
      />
    </>
  );
}

export default Uploadphoto;
