import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "../../App.scss";
import "./Model_call.scss";
import VideocamIcon from "@mui/icons-material/Videocam";
import { useNavigate } from "react-router-dom";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../../firebaseConfig/firebaseConfig";
import Spinners from "../../components/Spinner/Spinners";
import axios from "axios";

function Model_call_pending() {
  const navigate = useNavigate();
  const [pendingRequest, setPendingRequest] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [openModal, setOpenModal] = useState({ open: false, val: "" });
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (count == 0) {
      setIsLoading(true);
    }
    getPendingVideoCallRequest();
  }, [count]);
  const getPendingVideoCallRequest = () => {
    let pendingCalls = [];
    let pendingCall = query(
      collection(db, process.env.REACT_APP_VIDEO_CALL_REQUEST_COLLECTION_NAME),
      orderBy("requestedAt", "desc")
    );
    getDocs(pendingCall).then((list) => {
      list.forEach((call) => {
        pendingCalls.push({ ...call.data(), pendingCallDocId: call.id });
      });
      console.log("pendingCalls", pendingCalls);
      setPendingRequest(pendingCalls);
      setIsLoading(false);
    });
  };

  const update_video = (val) => {
    navigate("/back-s/update_video_call_schedule", {
      state: { updateCallTimings: val },
    });
  };

  const handleCallCompleted = (val) => {
    console.log("val", val);
    let completedObj = {
      callEndedAt: serverTimestamp(),
      date: val.date,
      email: val.email,
      mins: val.mins,
      name: val.name,
      noOfGems: val.noOfGems,
      phone: val.phone,
      requestedAt: val.requestedAt,
      specialReqMsg: val.specialReqMsg,
      status: false,
      time: val.time,
      uid: val.uid,
      userpic: val.userpic,
    };
    addDoc(
      collection(
        db,
        process.env.REACT_APP_VIDEO_CALL_COMPLETED_COLLECTION_NAME
      ),
      completedObj
    ).then(() => {
      deleteDoc(
        doc(
          db,
          process.env.REACT_APP_VIDEO_CALL_REQUEST_COLLECTION_NAME,
          val.pendingCallDocId
        )
      ).then(() => {
        setOpenModal(false);
        setCount(count + 1);
      });
    });
  };

  const handleCallStart = (val) => {
    axios
    .get(process.env.REACT_APP_VIDEOCALL_ACCESSTOKEN_API.replaceAll("{channelname}", process.env.REACT_APP_AGORA_CHANNEL_NAME))
    .then((response) => {
      console.log("response",response.data.token)
      let vdocalltokenObj = {
        accesstoken: response.data.token,
        channelName: "",
        livestarttime: serverTimestamp(),
        status: true,
        uid: ""
      }
       
      console.log("sdfsf", vdocalltokenObj);
      console.log('val', val)
      addDoc(collection(db, "vdocallaccesstoken"), vdocalltokenObj).then(()=>{
        let callObject = {
          call_end: false,
          call_request_id: val.pendingCallDocId,
          call_time: serverTimestamp(),
          call_token:response.data.token,
          channel_name: process.env.REACT_APP_AGORA_CHANNEL_NAME,
          has_model_join: true,
          has_user_join:false,
          rejected_by_user: false,
          user_id: val.uid,
          user_name: val.name
        }
           addDoc(collection(db,"call"),callObject).then((result)=>{
            navigate("/modalsideonetoonevideocall", {state: {callObject: {...callObject,docId: result.id},vdocalltokenObj: vdocalltokenObj, vdocallrequested: val }})
           })
      })
    });
  }
  return (
    <Box className="model_call_panding">
      {isLoading ? <Spinners /> : null}

      {pendingRequest &&
        pendingRequest.map((val) => {
          return (
            <Box className="contant_box b">
              <Typography
                variant="body2"
                className="typography-loginpage typography-size"
              >
                {val.name} requested video call for {val.mins} mins
              </Typography>
              <Typography
                variant="body2"
                className="typography-loginpage typography-size"
              >
                Contact:{val.phone}
              </Typography>
              <Typography
                variant="body2"
                className="typography-loginpage typography-size"
              >
                EmailId:{val.email}
              </Typography>
              <Typography
                variant="body2"
                className="typography-loginpage typography-size"
              >
                Requested on {val.time} {val.date}
              </Typography>
              {val?.specialReqMsg && (
                <Typography
                  variant="body2"
                  className="typography-loginpage typography-size"
                >
                  Special Outfits: {val?.specialReqMsg}
                </Typography>
              )}
              <Box sx={{ marginTop: "10px" }}>
              <Button variant="contained" className="model_call_video_btn" onClick={()=>handleCallStart(val)}>
                  <VideocamIcon />
                </Button>
                <Button
                  variant="contained"
                  className="model_call_video_btn1 typography-size"
                  onClick={() => update_video(val)}
                >
                  Update Call Schedule
                </Button>
              </Box>
              <Button
                variant="contained"
                className="model_call_video_btn1 btnc2 typography-size"
                onClick={() => setOpenModal({ open: true, val: val })}
              >
                Call Completed
              </Button>
            </Box>
          );
        })}
      {openModal.open && (
        <Dialog
          open={openModal.open}
          aria-labelledby="responsive-dialog-title "
          className="loguotpop"
          style={{ backgroundColor: "var(--pinkDark)" }}
        >
          <DialogTitle className="dialog-title-background typography-loginpage">
            Are you sure that you have Called?
          </DialogTitle>

          <DialogContent>
            <DialogContentText className="typography-loginpage">
              This cannot be undone. Once completed, you cannot see this request
              again.
            </DialogContentText>
            <Button
              className="typography-loginpage loginpage-button"
              onClick={() => handleCallCompleted(openModal.val)}
            >
              Yes I have Called
            </Button>
            <Button
              className="typography-loginpage loginpage-button"
              onClick={() => setOpenModal({ open: false, val: "" })}
            >
              Not Sure!
            </Button>
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
}

export default Model_call_pending;
