import React, { useState } from "react";
import "./Model_shoutout.scss";
import { Box } from "@mui/system";
import { Tab, Tabs, Typography } from "@mui/material";
import { TabPanel } from "../../components/Login/Tabelpanel";
import { useLocation, useNavigate } from "react-router-dom/dist";
import complete_shoutout from "../../assests/complete_shoutout.png";
import reject_shoutout from "../../assests/reject_shoutout.png";
import pending_shoutout from "../../assests/pending_shoutout.png";
import Model_shoutout_completed from "./Model_shoutout_Pending";
import Model_shoutout_Pedding from "./Model_shoutout_Completed";
import Model_shoutout_Rejected from "./Model_shoutout_Rejected";
import Model_shoutout_Pending from "./Model_shoutout_Pending";
import Model_shoutout_Completed from "./Model_shoutout_Completed";

function Model_shoutout() {
  const [values, setValues] = useState(0);
  const navigate = useNavigate();
  const route = useLocation();

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const handleChange = (event, newValue) => {
    setValues(newValue);
  };
  return (
    <>
      <Box className="m_tabbox">
        <Box className="tab">
          <Tabs
            value={values}
            onChange={handleChange}
            className="tab-menu "
            aria-label="basic tabs example"
          >
            <Tab
              className="typography-loginpage"
              label={
                <>
                  <Box>
                    <img
                      alt=""
                      src={complete_shoutout}
                      style={{ height: "20px", width: "20px" }}
                    />
                    <Typography variant="body1">{process.env.REACT_APP_COMPLETED_TEXT}</Typography>
                  </Box>
                </>
              }
              {...a11yProps(0)}
            />
            <Tab
              className="typography-loginpage"
              label={
                <>
                  <Box>
                    <img
                      alt=""
                      src={pending_shoutout}
                      style={{ height: "20px", width: "20px" }}
                    />
                    <Typography variant="body1">{process.env.REACT_APP_PENDING_TEXT}</Typography>
                  </Box>
                </>
              }
              {...a11yProps(1)}
            />
            <Tab
              className="typography-loginpage"
              label={
                <>
                  <Box>
                    <img
                      alt=""
                      src={reject_shoutout}
                      style={{ height: "20px", width: "20px" }}
                    />
                    <Typography variant="body1">{process.env.REACT_APP_REJECTED_TEXT}</Typography>
                  </Box>
                </>
              }
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <Box className="m_tabpanel">
          <TabPanel
            value={values}
            index={0}
            style={{
              position: "fixed",
              bottom: "0px",
              top: "165px",
              overflowY: "scroll",
              width: "100vw",
              overflowX: "hidden",
            }}
          >
            <Model_shoutout_Completed />
          </TabPanel>
          <TabPanel
            value={values}
            index={1}
            style={{
              position: "fixed",
              bottom: "0px",
              top: "165px",
              overflowY: "scroll",
              width: "100vw",
              overflowX: "hidden",
            }}
          >
            <Model_shoutout_Pending />
          </TabPanel>
          <TabPanel
            value={values}
            index={2}
            style={{
              position: "fixed",
              bottom: "0px",
              top: "165px",
              overflowY: "scroll",
              width: "100vw",
              overflowX: "hidden",
            }}
          >
            <Model_shoutout_Rejected />
          </TabPanel>
        </Box>
      </Box>
    </>
  );
}

export default Model_shoutout;
