import React, { useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./Model_Home.scss";
import HomeFeed from "./HomeFeed/HomeFeed";
import Overview from "./Overview/Overview";
import { TabPanel } from "../../components/Login/Tabelpanel";

function Model_Home() {
  const navigate = useNavigate();
  const [values, setValues] = useState(0);
  const [alignment, setAlignment] = useState(true);

  const handleAlignment = () => {
    setAlignment(!alignment);
  };
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const handleChange = (event, newValue) => {
    setValues(newValue);
  };

  return (
    <>
      <Box className="m_home">
        {/* <Box className="m_home_tab">
          <Tabs
            value={values}
            onChange={handleChange}
            className="tab-menu "
            aria-label="basic tabs example"
          >
            <Tab
              className="typography-loginpage"
              label="HomeFeed"
              {...a11yProps(0)}
            />
            <Tab
              className="typography-loginpage"
              label="Overview"
              {...a11yProps(1)}
            />
          </Tabs>
        </Box> */}

        {/* <Box className="tabpanels">
          <TabPanel value={values} index={0} className="a"> */}
            <HomeFeed />
          {/* </TabPanel> */}
          {/* <TabPanel value={values} index={1} className="a">
            <Overview />
          </TabPanel>
        </Box> */}
      </Box>
    </>
  );
}

export default Model_Home;
