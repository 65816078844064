import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./ShoutoutmyMessages.scss";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../../../firebaseConfig/firebaseConfig";
import Spinners from "../../../components/Spinner/Spinners";
import Spinner2 from "../../../components/Spinner/Spinner2";

function Rejected() {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem(process.env.REACT_APP_USERS_LOCALSTORAGE))
  );
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  let count = 0;
  useEffect(() => {
    getRejectedRequest();
  }, []);

  const getRejectedRequest = async () => {
    let RejectedRequest = [];
    const q1 = query(
      collection(
        db,
        process.env.REACT_APP_REJECTED_SHOUTOUT_REQUEST_COLLECTION
      ),
      where("uid", "==", user.idUser),
      orderBy("requestedOn", "desc")
    );
    let data = await getDocs(q1);
    data.forEach((doc) => RejectedRequest.push(doc.data()));
    setData(RejectedRequest);
    setLoading(false);
  };

  return (
    <>
      {loading ? <Spinner2 /> : null}
      {!loading && data.length == 0 && (
        <div
          style={{
            position: "fixed",
            top: "45%",
            left: 0,
            width: "100vw",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography>No Data</Typography>
        </div>
      )}
      {data.length > 0 &&
        data.map((val) => {
          let dateTimeString;
          if (val.requestedOn) {
            dateTimeString = `${val.requestedOn
              .toDate()
              .toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              })} ${new Date(val.requestedOn.toDate()).toLocaleDateString(
              "en-US",
              { year: "numeric", month: "long", day: "numeric" }
            )}`;
          }
          return (
            <Box className="Rejected_text_box">
              <Typography variant="body2" className="t">
                {process.env.REACT_APP_NAME_PLACEHOLDER}: &nbsp;{user.name}
              </Typography>
              {val.Email === "" ? (
                ""
              ) : (
                <Typography variant="body2" className="t">
                  {process.env.REACT_APP_MY_PROFILE_EMAIL_TEXTFIELD} :&nbsp;{" "}
                  {user.email}
                </Typography>
              )}
              <Typography variant="body2" className="t"></Typography>
              <Typography variant="body2" className="t">
                {process.env.REACT_APP_REQUESTED_AT_TEXT} &nbsp;{dateTimeString}
              </Typography>
              <Typography variant="body2" className="t">
                {process.env.REACT_APP_MESSAGE_TEXT}:&nbsp;{val.message}
              </Typography>
            </Box>
          );
        })}
    </>
  );
}

export default Rejected;
