import React, { useEffect, useState } from "react";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "../../firebaseConfig/firebaseConfig";
import { Box, Dialog } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Spinners from "../Spinner/Spinners";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
const Story = () => {
  const [open, setOpen] = useState({ open: false, val: "" });
  const [pageData, setPageData] = useState({
    story: [],
    loading: true,
  });
  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    const q1 = query(
      collection(db, process.env.REACT_APP_STORY_COLLECTION_NAME),
      orderBy("storyUploadTime", "asc")
    );

    getDocs(q1).then((result) => {
      const stroyArr = [];
      result.forEach((doc) => {
        stroyArr.push(doc.data());
      });
      setPageData({ story: stroyArr, loading: false });
    });
  };

  {
    return (
      <div>
        {pageData.loading ? (
          <Spinners />
        ) : (
          <div className="story">
            {pageData.story.map((ele) => {
              return (
                <div
                  className="data-image"
                  onClick={() => setOpen({ open: true, val: ele })}
                >
                  <div className="story-div"><img src={ele.storyPicUrl} /></div>
                </div>
              );
            })}
          </div>
        )}
        {open.open && (
          <Dialog fullScreen open={open.open} className="spop">
            <Box>
              <Box className="op">
                <Box className="mainop">
                  <Box
                    className="backicon"
                    style={{ position: "absolute", top: "2%", zIndex: "2" }}
                    onClick={() => setOpen(false)}
                  >
                    <ArrowBackIcon />
                  </Box>
                  <Box className="opBox">
                    <TransformWrapper
                      defaultScale={1}
                      defaultPositionX={100}
                      defaultPositionY={200}
                    >
                      <TransformComponent
                        contentStyle={{ width: "100vw", height: "100vh" }}
                      >
                        <img
                          alt=""
                          src={open.val.storyPicUrl}
                          className="opimg"
                        />
                      </TransformComponent>
                    </TransformWrapper>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Dialog>
        )}
      </div>
    );
  }
};

export default Story;
