import React, { useEffect, useRef, useState } from "react";
import { TabPanel } from "./Tabelpanel";
import "../../App.scss";
import "./Login.scss";
import BackGround from "../../assests/login.jpg";
import Logo from "../../assests/logo.png";
import { useNavigate } from "react-router";
import ModalComponent from "../ModalComponent/ModalComponent";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { authErrors } from "./errors";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { authentication, db } from "../../firebaseConfig/firebaseConfig";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  where,
} from "@firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import "react-phone-input-2/lib/style.css";
import Country1 from "./Countycode";
import { FlagIcon } from "react-flag-kit";
import Spinners from "../Spinner/Spinners";

const Login = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  let timeOut = null;
  const [search, setSearch] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("+91");
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [text, setText] = useState("");
  const [count, setCount] = useState(0);
  const [counter, setCounter] = useState(0);
  const [confirmResultHook, setConfirmResultHook] = useState("");
  const [appVerifier, setAppVerifier] = useState(null);
  // const [user] = useAuthState(auth);
  const [editDetails, setEditDetails] = useState(0);
  const [mobileData, setMobileData] = useState({
    mobile_number: "",
    OTP: "",
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [alreadyLoginuser, setAlreadyLoginUser] = useState(
    JSON.parse(localStorage.getItem(process.env.REACT_APP_USERS_LOCALSTORAGE))
  );
  const [modal, setModal] = useState(
    JSON.parse(localStorage.getItem(process.env.REACT_APP_ISMODAL_FLAG))
  );

  useEffect(() => {
    if (alreadyLoginuser) {
      navigate("/userhomepage/home");
    } else if (modal) {
      const modaldeatails = doc(
        db,
        process.env.REACT_APP_RECHARGE_GEMS_COLLECTION_NAME,
        "allowModelLogin"
      );
      getDoc(modaldeatails).then((result) => {
        if (result.data()?.web) {
          localStorage.setItem("isModal", true);
          navigate("/a/Home");
        }
      });
    }
  }, [editDetails]);

  if (count > 0) {
    timeOut = setTimeout(() => {
      setCount(count - 1);
    }, 1000);
  }

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const verifyFields = () => {
    setConfirmResultHook("");
    if (mobileData.mobile_number.trim() == "") {
      setText(process.env.REACT_APP_MOBILE_VALIDATION_TEXT_MODAL);
      setOpen(true);
    } else if (
      mobileData.mobile_number.trim().length < 8 ||
      mobileData.mobile_number.trim().length > 15
    ) {
      setText(
        process.env.REACT_APP_MODILE_NUMBER_VALIDATION_TEXT_FOR_MOBILE_NUMBER
      );
      setOpen(true);
    } else {
      const phoneNumber = `${selectedCountry.trim()}${mobileData.mobile_number.trim()}`;
      let tempAppVerifier = null;
      if (!appVerifier) {
        tempAppVerifier = new RecaptchaVerifier(
          "recaptcha-container",
          {
            size: "invisible",
            callback: (response) => {},
            "expired-callback": () => {},
          },
          authentication
        );
      } else {
        tempAppVerifier = appVerifier;
      }
      setCount(60);
      setCounter(counter + 1);
      signInWithPhoneNumber(authentication, phoneNumber, tempAppVerifier)
        .then((confirmationResult) => {
          if (!appVerifier) {
            setAppVerifier(tempAppVerifier);
          }
          setConfirmResultHook(confirmationResult);
        })
        .catch((error) => {
          console.log("spec_chcek_ecrr", error);
          setOpen(true);
          setText(error.code);
        });
    }
  };
  const proceed = () => {
    if (mobileData.OTP.trim() == "") {
      setOpen(true);
      setText(process.env.REACT_APP_OTP_CODE_MODAL_TEXT);
    } else if (mobileData.OTP.trim().length !== 6) {
      setOpen(true);
      setText(process.env.REACT_APP_INVALID_OTP_CODE_MODAL_TEXT);
    } else if (count > 0) {
      setLoading(true);
      confirmResultHook
        .confirm(mobileData.OTP)
        .then(async (result) => {
          setLoading(true);
          const user = result.user;
          const q1 = doc(
            db,
            process.env.REACT_APP_USERS_COLLECTION_NAME,
            `${user.uid}`
          );
          const querySnapshot1 = await getDoc(q1);
          if (!querySnapshot1.data()?.blocked && querySnapshot1.data()) {
            localStorage.setItem(
              process.env.REACT_APP_USERS_LOCALSTORAGE,
              JSON.stringify(querySnapshot1.data())
            );
            const q2 = doc(
              db,
              process.env.REACT_APP_WALLET_COLLECTION_NAME,
              `${user.uid}`
            );
            const querySnapshot2 = await getDoc(q2);
            localStorage.setItem(
              process.env.REACT_APP_WALLET_LOCALSTORAGE,
              JSON.stringify(querySnapshot2.data())
            );
            const q3 = query(
              collection(
                db,
                process.env.REACT_APP_MYPURCHASEDPHOTOS_COLLECTION_NAME
              ),
              where("uid", "==", user.uid)
            );
            const photos = [];
            const querySnapshot3 = await getDocs(q3);
            querySnapshot3.forEach((doc) => {
              photos.push([...photos, doc.data()]);
            });
            localStorage.setItem(
              process.env.REACT_APP_PURCHASEDPHOTO_LOCALSTORAGE,
              JSON.stringify(photos)
            );
            const q4 = query(
              collection(
                db,
                process.env.REACT_APP_PURCHASEDVIDEOS_COLLECTION_NAME
              ),
              where("uid", "==", user.uid)
            );
            const videos = [];
            const querySnapshot4 = await getDocs(q4);
            querySnapshot4.forEach((doc) => {
              videos.push(...videos, doc.data());
            });
            localStorage.setItem(
              process.env.REACT_APP_PURCHASEDVIDEOS_LOCALSTORAGE,
              JSON.stringify(videos)
            );
            const deviceToken = {
              deviceToken: JSON.parse(
                localStorage.getItem(
                  process.env.REACT_APP_DEVICETOKEN_LOCALSTORAGE
                )
              ),
              fcmTokenTime: serverTimestamp(),
              localAppVersionOfTheUser: "",
              platform: process.env.REACT_APP_PLATFORM,
              uid: user.uid,
            };
            await addDoc(
              collection(db, process.env.REACT_APP_DEVICETOKEN_COLLECTION),
              deviceToken
            ).then(() => {
              localStorage.setItem(
                process.env.REACT_APP_PROMATEIMAGE_LOCALSTORAGE,
                true
              );
              navigate("/userhomepage/home");
              setLoading(false);
            });
          } else {
            setLoading(false);
            setOpen(true);
            setText("This user doesn't exist. Please sign up to the website.");
          }
        })
        .catch((error) => {
          setLoading(false);
          setOpen(true);
          setText(process.env.REACT_APP_INVALID_PHONE_CREDENTIAL_MODAL_TEXT);
        });
      setLoading(false);
    } else {
      setText(process.env.REACT_APP_TIME_EXCEEDS_MODAL_TEXT);
      setOpen(true);
    }
  };

  const close = () => {
    setOpen(false);
  };

  const verifyEmailFields = () => {
    if (mobileData.email.trim() == "") {
      setText(process.env.REACT_APP_ENTER_EMAIL_MODAL_TEXT);
      setOpen(true);
    } else if (
      /^[a-zA-Z0-9]+@+[a-zA-Z0-9]+.+[A-z]/.test(mobileData.email) == false
    ) {
      setText(process.env.REACT_APP_INVALID_EMAIL_MODAL_TEXT);
      setOpen(true);
    } else if (mobileData.password.trim() == "") {
      setText(process.env.REACT_APP_ENTER_PASSWORD_MODAL_TEXT);
      setOpen(true);
    } else {
      setLoading(true);
      let flag = process.env.REACT_APP_MODEL_UID;
      signInWithEmailAndPassword(
        auth,
        mobileData.email.trim(),
        mobileData.password.trim()
      )
        .then(async (userCredential) => {
          const user = userCredential.user;
          const isModal = user.uid == process.env.REACT_APP_MODEL_UID;
          if (isModal) {
            const q1 = doc(
              db,
              process.env.REACT_APP_RECHARGE_GEMS_COLLECTION_NAME,
              "allowModelLogin"
            );
            getDoc(q1).then((result) => {
              // if (result.data()?.web) {
              localStorage.setItem("isModal", true);
              navigate("/a/Home");
              // } else {
              //   setLoading(false);
              //   setText("Incorrect user credentials");
              //   setOpen(true);
              // }
            });
          }
          if (!isModal) {
            let userForTesting = {};
            const testUser = query(
              collection(
                db,
                process.env.REACT_APP_RECHARGE_GEMS_COLLECTION_NAME,
                "testUser",
                "userData"
              ),
              where("userId", "==", user.uid)
            );
            getDocs(testUser).then(async (testUsers) => {
              testUsers.forEach((testUserInfo) => {
                userForTesting = { ...testUserInfo.data() };
              });
              let testUserFlag = Object.keys(userForTesting).length > 0;
              console.log(testUserFlag, "testUserFlag");
              const q1 = doc(
                db,
                process.env.REACT_APP_USERS_COLLECTION_NAME,
                `${user.uid}`
              );
              const querySnapshot1 = await getDoc(q1);
              if (testUserFlag) {
                localStorage.setItem("testUser", JSON.stringify(testUserFlag));
              }
              localStorage.setItem(
                "users",
                JSON.stringify(querySnapshot1.data())
              );
              if (!querySnapshot1.data().blocked) {
                const q2 = doc(
                  db,
                  process.env.REACT_APP_WALLET_COLLECTION_NAME,
                  `${user.uid}`
                );
                const querySnapshot2 = await getDoc(q2);
                localStorage.setItem(
                  process.env.REACT_APP_WALLET_LOCALSTORAGE,
                  JSON.stringify(querySnapshot2.data())
                );
                const q3 = query(
                  collection(
                    db,
                    process.env.REACT_APP_MYPURCHASEDPHOTOS_COLLECTION_NAME
                  ),
                  where("uid", "==", user.uid)
                );
                const photos = [];
                const querySnapshot3 = await getDocs(q3);
                querySnapshot3.forEach((doc) => {
                  photos.push(doc.data());
                });
                localStorage.setItem(
                  process.env.REACT_APP_PURCHASEDPHOTO_LOCALSTORAGE,
                  JSON.stringify(photos)
                );
                const q4 = query(
                  collection(
                    db,
                    process.env.REACT_APP_PURCHASEDVIDEOS_COLLECTION_NAME
                  ),
                  where("uid", "==", user.uid)
                );
                const videos = [];
                const querySnapshot4 = await getDocs(q4);
                querySnapshot4.forEach((doc) => {
                  videos.push(doc.data());
                });
                localStorage.setItem(
                  process.env.REACT_APP_PURCHASEDVIDEOS_LOCALSTORAGE,
                  JSON.stringify(videos)
                );
                const deviceToken = {
                  deviceToken: JSON.parse(
                    localStorage.getItem(
                      process.env.REACT_APP_DEVICETOKEN_LOCALSTORAGE
                    )
                  ),
                  fcmTokenTime: serverTimestamp(),
                  localAppVersionOfTheUser: process.env.REACT_APP_VERSION,
                  platform: process.env.REACT_APP_PLATFORM,
                  uid: user.uid,
                };
                await addDoc(
                  collection(db, process.env.REACT_APP_DEVICETOKEN_COLLECTION),
                  deviceToken
                ).then(() => {
                  localStorage.setItem(
                    process.env.REACT_APP_PROMATEIMAGE_LOCALSTORAGE,
                    true
                  );
                  navigate("/userhomepage/home");
                  setLoading(false);
                });
              }
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          const errArr = err.code.split("/");
          setText(authErrors[`${errArr[errArr.length - 1]}`]);
          setOpen(true);
        });
    }
  };
  return (
    <Box className="general">
      {loading ? <Spinners /> : null}
      <Box
        className="login-container"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, -1), rgba(0,0,0,0.89)),url(${BackGround})`,
        }}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src={Logo} />
        </div>

        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            className="typography-loginpage"
            label={process.env.REACT_APP_EMAIL_BUTTON}
            {...a11yProps(0)}
          />
          <Tab
            className="typography-loginpage"
            label={process.env.REACT_APP_MOBILE_BUTTON}
            {...a11yProps(1)}
          />
        </Tabs>
        <TabPanel value={value} index={0} style={{ width: "90%" }}>
          <TextField
            placeholder={process.env.REACT_APP_ENTER_EMAIL_ID_PLACEHOLDER}
            variant="outlined"
            type="email"
            focused={false}
            onChange={(e) =>
              setMobileData({ ...mobileData, email: e.target.value })
            }
          />
          <FormControl
            variant="outlined"
            fullWidth
            style={{ marginBottom: "10px" }}
          >
            <OutlinedInput
              autoComplete="no"
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              className="passinput"
              focused={false}
              onChange={(e) =>
                setMobileData({ ...mobileData, password: e.target.value })
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    style={{ color: "var(--white)" }}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            className="loginpage-button typography-loginpage button-color"
            style={{
              width: "100%",
              marginBottom: "25px",
              textTransform: "none",
              fontSize: "20px",
            }}
            onClick={() => {
              verifyEmailFields();
            }}
          >
            {process.env.REACT_APP_PROCEED_BUTTON}
          </Button>
        </TabPanel>
        <TabPanel value={value} index={1} style={{ width: "90%" }}>
          <Box sx={{ display: "flex" }}>
            <FormControl className="selectfield">
              <Select
                labelId="demo-simple-select-label"
                renderValue={(selectedCountry) => selectedCountry}
                id="demo-simple-select"
                className="selectcode"
                value={selectedCountry}
                MenuProps={{ autoFocus: false }}
                onChange={(e) => {
                  setSelectedCountry(e.target.value);
                }}
              >
                <Box sx={{ padding: "0px 14px" }}>
                  <Typography variant="h6">
                    {process.env.REACT_APP_SELECT_HEADING}
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    variant="standard"
                    label="Search..."
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                  ></TextField>
                </Box>
                {Country1.filter((val) => {
                  if (search === "") {
                    return val;
                  } else if (
                    val.name.toLowerCase().includes(search.toLowerCase()) ||
                    val.code.toLowerCase().includes(search.toLowerCase()) ||
                    val.cca2.toLowerCase().includes(search.toLowerCase())
                  ) {
                    return val;
                  }
                }).map((ev) => {
                  return (
                    <MenuItem value={ev.code} className="menucountry">
                      <FlagIcon code={ev.flag} />
                      <span id="countryname">
                        {ev.name}
                        &nbsp;({ev.cca2})
                      </span>
                      <span className="ccode">{ev.code}</span>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <TextField
              type="tel"
              placeholder={
                process.env.REACT_APP_ENTER_MOBILE_NUMBER_PLACEHOLDER
              }
              variant="outlined"
              onChange={(e) =>
                setMobileData({ ...mobileData, mobile_number: e.target.value })
              }
              style={{ marginBottom: "15px", width: "80%", marginLeft: "5px" }}
              focused={false}
              inputProps={{ maxLength: 15 }}
            />
          </Box>
          {count !== 0 ? (
            <Typography
              className="text-align-center"
              style={{ marginBottom: "15px" }}
            >
              Resend OTP in {count}
            </Typography>
          ) : null}

          {count !== 0 || counter > 0 ? (
            <TextField
              placeholder="Enter OTP"
              type="number"
              variant="outlined"
              onChange={(e) =>
                setMobileData({ ...mobileData, OTP: e.target.value })
              }
              style={{ marginBottom: "15px", width: "100%" }}
              focused={false}
              inputProps={{ maxLength: 6 }}
            />
          ) : null}

          {counter === 0 || (counter > 0 && count === 0) ? (
            <Button
              variant="contained"
              className="loginpage-button typography-loginpage button-color"
              color="primary"
              style={{
                marginBottom: "25px",
                width: "100%",
                textTransform: "none",
                fontSize: "20px",
              }}
              onClick={() => {
                setEditDetails(editDetails + 1);
                verifyFields();
              }}
            >
              {process.env.REACT_APP_SEND_OTP_BUTTON}
            </Button>
          ) : null}
          {count !== 0 || counter > 0 ? (
            <Button
              variant="contained"
              className="loginpage-button typography-loginpage button-color"
              color="primary"
              style={{
                marginBottom: "25px",
                width: "100%",
                textTransform: "none",
                fontSize: "20px",
              }}
              onClick={() => {
                proceed();
              }}
            >
              {process.env.REACT_APP_PROCEED_BUTTON}
            </Button>
          ) : null}
        </TabPanel>
        <Typography
          className="typography-loginpage"
          style={{ marginBottom: "15px", fontFamily: "var(--font)" }}
          onClick={() => navigate("/forgot-password")}
        >
          {process.env.REACT_APP_FORGOT_PASSWORD_HEADING}
        </Typography>
        <Typography
          className="typography-loginpage"
          style={{ marginBottom: "25px", fontFamily: "var(--font)" }}
        >
          {process.env.REACT_APP_NEW_USER_HEADING}
          <a
            href="#"
            style={{ marginLeft: "5px" }}
            onClick={() => navigate("/prelogin/register")}
          >
            {process.env.REACT_APP_SIGNIN_USER_HEADING}
          </a>
        </Typography>
        <ModalComponent
          close={close}
          open={open}
          text={text}
          title={process.env.REACT_APP_DIALOG_APP_NAME}
        />
        <div id="recaptcha-container" />
      </Box>
    </Box>
  );
};

export default Login;
