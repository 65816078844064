import { Box, Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import play_icon from "../../component/Images/play_icon.png";
import "./Videopage.scss";

import FavoriteIcon from "@mui/icons-material/Favorite";
import MessageIcon from "@mui/icons-material/Message";
import LockIcon from "@mui/icons-material/Lock";
import { useNavigate } from "react-router-dom";
import ModelProfilePicture from "../../assests/login.jpg";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  serverTimestamp,
  startAfter,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebaseConfig/firebaseConfig";
import InfiniteScroll from "react-infinite-scroll-component";
import Spinners from "../../components/Spinner/Spinners";
import Comment from "../commentpage/Commentpage";
import ModalforPurchaseVideo from "./ModalforPurchaseVideo";
import { getAuth } from "firebase/auth";

function Videopage() {
  const auth = getAuth();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem(process.env.REACT_APP_USERS_LOCALSTORAGE))
  );
  const [lastDoc, setLastDoc] = useState(null);
  const userID = JSON.parse(
    localStorage.getItem(process.env.REACT_APP_USERS_LOCALSTORAGE)
  ).idUser;
  const navigate = useNavigate();
  const [open, setOpen] = useState({ open: false, val: "" });
  const [wallet, setWallet] = useState();
  const [testUser, setTestUser] = useState(
    JSON.parse(localStorage.getItem("testUser") ?? false)
  );

  const [video, setVideo] = useState({});
  const [myPurchedVideos, setMyPurchedVideos] = useState(
    JSON.parse(
      localStorage.getItem(process.env.REACT_APP_PURCHASEDVIDEOS_LOCALSTORAGE)
    )
  );
  const [openComment, setOpenComment] = useState({ open: false, val: "" });

  useEffect(() => {
    console.log("first");
    getVideosData();
    window.scrollTo({ top: 0, behavior: "smooth" });
    const q2 = doc(
      db,
      process.env.REACT_APP_WALLET_COLLECTION_NAME,
      user.idUser
    );
    getDoc(q2).then((response) => {
      const walletCollectionData = response?.data();
      setWallet(walletCollectionData);
    });
    // setLoading(false);
  }, []);

  const getVideosData = (lastDoc = null) => {
    setLoading(true);
    let q4;
    if (lastDoc) {
      q4 = query(
        collection(db, process.env.REACT_APP_VIDEOS_COLLECTION),
        orderBy("createdAt", "desc"),
        startAfter(lastDoc),
        limit(15)
      );
    } else {
      console.log("second");
      q4 = query(
        collection(db, process.env.REACT_APP_VIDEOS_COLLECTION),
        orderBy("createdAt", "desc"),
        limit(15)
      );
    }

    getDocs(q4).then((result) => {
      console.log("thired");
      let videos = {};
      setLastDoc(result.docs[result.docs.length - 1]);
      result.forEach((doc) => {
        videos = {
          ...videos,
          [doc.id]: {
            ...doc.data(),
            id: doc.id,
            likedCounter: 0,
            commentCounter: 0,
            myLiked: false,
          },
        };
      });
      setVideo({ ...video, ...videos });
      getLikedCounters(videos);
      setLoading(false);
    });
  };

  const getLikedCounters = (videos) => {
    console.log("4");
    const promises = Object.keys(videos).map((id) => {
      console.log("5");
      const q1 = query(
        collection(
          db,
          process.env.REACT_APP_VIDEOS_COLLECTION,
          id,
          process.env.REACT_APP_LIKED_COLLECTION
        )
      );
      return getDocs(q1);
    });
    const promisesComments = Object.keys(videos).map((id) => {
      console.log("6");
      const q2 = query(
        collection(
          db,
          process.env.REACT_APP_VIDEOS_COMMENTS_COLLECTION,
          id,
          process.env.REACT_APP_COMMENTS_COLLECTION
        )
      );
      return getDocs(q2);
    });
    Promise.all([...promises, ...promisesComments]).then((values) => {
      console.log(values);
      values.map((data) => {
        console.log(data, "data");
        if (!data.empty) {
          const path = data.query.path.split("/");
          const docID = path[1];
          if (path[2] == "comments") {
            videos[docID].commentCounter = data.docs.length;
          } else {
            const myLiked =
              data.docs.filter((x) => x.data().userId == userID).length > 0;
            videos[docID].likedCounter = data.docs.length;
            videos[docID].myLiked = myLiked;
          }
        }
      });
      setVideo({ ...video, ...videos });
    });
  };

  const handleLike = (ele) => {
    let obj = {
      userId: userID,
    };
    addDoc(
      collection(
        db,
        process.env.REACT_APP_VIDEOS_COLLECTION,
        `${ele.id}`,
        process.env.REACT_APP_LIKED_COLLECTION
      ),
      obj
    ).then(() => {});
    setVideo({
      ...video,
      [`${ele.id}`]: {
        ...video[`${ele.id}`],
        likedCounter: video[`${ele.id}`].likedCounter + 1,
        myLiked: true,
      },
    });
  };

  const handleComment = (ele) => {
    setVideo({
      ...video,
      [`${ele.id}`]: {
        ...video[`${ele.id}`],
        commentCounter: video[`${ele.id}`].commentCounter + 1,
      },
    });
  };

  const handleClickOpen = (ele) => {
    setOpen({ open: true, val: ele });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const closeComment = () => {
    setOpenComment(false);
  };

  const handlePurchase = (val) => {
    let videoPurchase = {
      boughtOn: serverTimestamp(),
      thumbUrl: val.thumbUrl,
      vdoUrl: val.vdoUrl,
      vdocreatedAt: val.createdAt,
      vdodesc: val.desc,
      vdoid: val.id,
      vdomonitizeState: process.env.REACT_APP_UPLOAD_VIDEO_MONITIZE_STATE,
      vdonoofgems: val.noOfGems,
      vdotitle: val.title,
      platform: process.env.REACT_APP_PLATFORM,
      uid: auth.currentUser.uid,
      version: process.env.REACT_APP_VERSION,
    };
    let updatedWallet = {
      ...wallet,
      balance: wallet?.balance - val.noOfGems,
    };
    setWallet(updatedWallet);
    localStorage.setItem(
      process.env.REACT_APP_WALLET_LOCALSTORAGE,
      JSON.stringify(updatedWallet)
    );

    const querySnapshot = doc(
      db,
      process.env.REACT_APP_WALLET_COLLECTION_NAME,
      user?.idUser
    );
    updateDoc(querySnapshot, {
      balance: updatedWallet.balance,
    }).then(() => {});
    setMyPurchedVideos([...myPurchedVideos, videoPurchase]);
    localStorage.setItem(
      process.env.REACT_APP_PURCHASEDVIDEOS_LOCALSTORAGE,
      JSON.stringify([...myPurchedVideos, videoPurchase])
    );
    addDoc(
      collection(db, process.env.REACT_APP_PURCHASEDVIDEOS_COLLECTION_NAME),
      videoPurchase
    ).then((result) => {
      let VideoPurchased_transaction_type_name =
        process.env.REACT_APP_VDO_TRANSACTION_TYPE_NAME;
      let VideoPurchased_transaction_type_id =
        process.env.REACT_APP_VDO_TRANSACTION_TYPE_ID;
      let userTransactionnsTable = {
        dm_message_gif: "",
        dm_message_image: "",
        dm_message_text: "",
        live_session_purchase_gif_name: "",
        live_session_purchase_comment_message: "",

        live_session_ref_id: "",
        platform: process.env.REACT_APP_PLATFORM,
        purchased_media_description: videoPurchase.vdodesc,
        purchased_media_title: videoPurchase.vdotitle,
        purchased_media_url: videoPurchase.vdoUrl,
        purchased_product_description: "",
        purchased_product_title: "",
        recharge_gems_transaction_amount: "",
        recharge_gems_transaction_id: "",
        transaction_gems: videoPurchase.vdonoofgems,
        transaction_gems_string: `-${videoPurchase.vdonoofgems}`,
        transaction_ref_id: videoPurchase.vdoid,
        transaction_time: serverTimestamp(),
        transaction_type_id: VideoPurchased_transaction_type_id,
        transaction_type_name: VideoPurchased_transaction_type_name,
        updated_balance_after_transaction: `${updatedWallet.balance}`,
        user_email: user?.email,
        user_id: user?.idUser,
        user_name: user?.name,
        version: process.env.REACT_APP_VERSION,
      };
      addDoc(
        collection(db, process.env.REACT_APP_USERTRANSACTIONS_COLLECTION),
        userTransactionnsTable
      ).then(() => {});
    });

    setOpen({ open: false, val: "" });
  };

  return (
    <Box className="general">
      <Box className="homecss">
        <Box className="homepage">
          {!loading && Object.keys(video).length === 0 ? (
            <Typography style={{ top: "50%", left: "40%", position: "fixed" }}>
              No Data
            </Typography>
          ) : (
            <>
              {loading && <Spinners />}
              <Box>
                <Box className="videooheadingdiv">
                  <Typography variant="h6" id="videoheading">
                    {process.env.REACT_APP_VIDEO_PAGE_TITLE}
                  </Typography>
                </Box>
                <Box className="videomain">
                  <InfiniteScroll
                    dataLength={Object.keys(video).length}
                    next={() => {
                      getVideosData(lastDoc);
                    }}
                    hasMore={true}
                  >
                    {Object.values(video).map((ele) => {
                      let isFree;
                      if (testUser) {
                        isFree = true;
                      } else {
                        isFree = ele.noOfGems == 0;
                      }
                      let isPurchased;
                      if (testUser) {
                        isPurchased = true;
                      } else {
                        isPurchased =
                          myPurchedVideos.filter((pho) => pho.vdoid == ele.id)
                            .length > 0;
                      }
                      const lockedStrip = !isFree
                        ? `${
                            !isPurchased
                              ? process.env.REACT_APP_LOCKED_STRIP_TEXT
                              : process.env.REACT_APP_UNLOCKED_STRIP_TEXT
                          } Video`
                        : false;

                      return (
                        <Box
                          onClick={() =>
                            !isPurchased && !isFree
                              ? setOpen({ open: true, val: ele })
                              : null
                          }
                        >
                          <Grid
                            container
                            className="videodiv"
                            columnSpacing={0}
                          >
                            <Grid item className="videodiv_video">
                              <div className="profilepic">
                                <img
                                  alt=""
                                  src={ModelProfilePicture}
                                  className="video"
                                />
                              </div>
                            </Grid>
                            <Grid item className="videodiv_description">
                              <Typography variant="body1" id="videousername">
                                {process.env.REACT_APP_MODAL_NAME}
                              </Typography>
                              <Typography variant="body2" id="videocaption">
                                {ele.desc}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Box
                            className="postvideodiv"
                            onClick={() =>
                              isFree || isPurchased
                                ? navigate("/videos", { state: { val: ele } })
                                : handleClickOpen(ele)
                            }
                          >
                            <Box className="postdivvideo">
                              <img
                                alt=""
                                src={ele.thumbUrl}
                                className="postvideo"
                              />
                            </Box>
                            <Box className="videolockcontant">
                              <img alt="" src={play_icon} id="playimg" />
                            </Box>
                            <Box className="videolockcontant">
                              <img alt="" src={play_icon} id="playimg" />
                              {!isPurchased && !isFree && (
                                <Typography variant="body1">
                                  Unlock Video at {ele.noOfGems} GEMS
                                </Typography>
                              )}
                            </Box>
                            {!isFree ? (
                              <Box className="videoimlock">
                                <LockIcon
                                  style={{
                                    fontSize: "18px",
                                    marginRight: "2px",
                                  }}
                                />
                                <Typography variant="body2">
                                  {lockedStrip}
                                </Typography>
                              </Box>
                            ) : null}
                          </Box>
                          {(isFree || isPurchased) && (
                            <Grid container className="videolikediv">
                              <Grid
                                item
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <IconButton>
                                  {ele.myLiked ? (
                                    <FavoriteIcon
                                      id="btnlikecmvideo"
                                      style={{ color: "var(--red)" }}
                                    />
                                  ) : (
                                    <FavoriteIcon
                                      id="btnlikecmvideo"
                                      onClick={() => handleLike(ele)}
                                    />
                                  )}
                                </IconButton>
                                <Typography variant="body1" id="videousername">
                                  {ele.likedCounter}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                sx={{ display: "flex", alignItems: "center" }}
                                onClick={() =>
                                  setOpenComment({ open: true, val: ele })
                                }
                              >
                                <IconButton>
                                  <MessageIcon id="btnlikecmvideo" />
                                </IconButton>

                                <Typography variant="body1" id="videousername">
                                  {ele.commentCounter}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          <Box className="photoendline" />
                        </Box>
                      );
                    })}
                  </InfiniteScroll>
                </Box>
                <Box>
                  {open.open && (
                    <ModalforPurchaseVideo
                      open={open.open}
                      val={open}
                      wallet={wallet}
                      close={() => handleClose()}
                      handlePurchase={(ele) => handlePurchase(ele)}
                    />
                  )}
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>
      {openComment?.open && (
        <Comment
          open={openComment.open}
          close={() => closeComment()}
          val={openComment.val}
          from="photospage"
          addCommentCount={(ele) => handleComment(ele)}
        />
      )}
    </Box>
  );
}

export default Videopage;
