import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebaseConfig/firebaseConfig";
import Opimg from "../../User/Opimg/Opimg";
import ModalforPurchase from "../../User/Photopage/ModalforPurchase";
import { getAuth } from "firebase/auth";

const TopPhotos = () => {
  const auth = getAuth();
  const [photo, setPhoto] = useState([]);
  const [wallet, setWallet] = useState();
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem(process.env.REACT_APP_USERS_LOCALSTORAGE))
  );
  const [openPhoto, setOpenPhoto] = useState({ open: false, val: "" });
  const [open, setOpen] = useState({ open: false, val: "" });
  const [myPurchedPhotos, setMyPurchedPhotos] = useState(
    JSON.parse(
      localStorage.getItem(process.env.REACT_APP_PURCHASEDPHOTO_LOCALSTORAGE)
    )
  );
  const [testUser, setTestUser] = useState(
    JSON.parse(localStorage.getItem("testUser") ?? false)
  );
  useEffect(() => {
    getData();
    const q2 = doc(
      db,
      process.env.REACT_APP_WALLET_COLLECTION_NAME,
      user.idUser
    );
    getDoc(q2).then((response) => {
      const walletCollectionData = response?.data();
      setWallet(walletCollectionData);
    });
  }, []);
  const getData = () => {
    const q4 = query(
      collection(db, process.env.REACT_APP_PHOTOS_COLLECTION),
      orderBy("likeCounter", "desc"),
      limit(18)
    );
    getDocs(q4).then((result) => {
      const photos = [];
      result.forEach((doc) => {
        photos.push({ ...doc.data(), id: doc.id });
      });
      setPhoto(photos);
    });
  };
  const closePhoto = () => {
    setOpenPhoto(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handlePurchase = (val) => {
    let photoPurchase = {
      boughtOn: serverTimestamp(),
      photoUrl: val.photoUrl,
      photocreatedAt: val.createdAt,
      photodesc: val.desc,
      photoid: val.id,
      photomonitizeState: "",
      photonoofgems: val.noOfGems,
      phototitle: val.title,
      platform: process.env.REACT_APP_PLATFORM,
      uid: auth.currentUser.uid,
      version: process.env.REACT_APP_VERSION,
    };
    let updatedWallet = {
      ...wallet,
      balance: wallet?.balance - val.noOfGems,
    };
    setWallet(updatedWallet);
    localStorage.setItem(
      process.env.REACT_APP_WALLET_LOCALSTORAGE,
      JSON.stringify(updatedWallet)
    );

    const querySnapshot = doc(
      db,
      process.env.REACT_APP_WALLET_COLLECTION_NAME,
      user.idUser
    );
    updateDoc(querySnapshot, {
      balance: updatedWallet.balance,
    }).then(() => {});
    setMyPurchedPhotos([...myPurchedPhotos, photoPurchase]);
    localStorage.setItem(
      process.env.REACT_APP_PURCHASEDPHOTO_LOCALSTORAGE,
      JSON.stringify([...myPurchedPhotos, photoPurchase])
    );
    addDoc(
      collection(db, process.env.REACT_APP_MYPURCHASEDPHOTOS_COLLECTION_NAME),
      photoPurchase
    ).then((result) => {
      let photo_transaction_type_name =
        process.env.REACT_APP_PHOTO_TRANSACTION_TYPE_NAME;
      let photo_transaction_type_id =
        process.env.REACT_APP_PHOTO_TRANSACTION_TYPE_ID;
      let userTransactionnsTable = {
        dm_message_gif: "",
        dm_message_image: "",
        dm_message_text: "",
        live_session_purchase_gif_name: "",
        live_session_purchase_comment_message: "",
        live_session_ref_id: "",
        platform: process.env.REACT_APP_PLATFORM,
        purchased_media_description: photoPurchase.photodesc,
        purchased_media_title: photoPurchase.phototitle,
        purchased_media_url: photoPurchase.photoUrl,
        purchased_product_description: "",
        purchased_product_title: "",
        recharge_gems_transaction_amount: "",
        recharge_gems_transaction_id: "",
        transaction_gems: photoPurchase.photonoofgems,
        transaction_gems_string: `-${photoPurchase.photonoofgems}`,
        transaction_ref_id: photoPurchase.photoid,
        transaction_time: serverTimestamp(),
        transaction_type_id: photo_transaction_type_id,
        transaction_type_name: photo_transaction_type_name,
        updated_balance_after_transaction: `${updatedWallet.balance}`,
        user_email: user.email,
        user_id: user.idUser,
        user_name: user.name,
        version: process.env.REACT_APP_VERSION,
      };
      addDoc(
        collection(db, process.env.REACT_APP_USERTRANSACTIONS_COLLECTION),
        userTransactionnsTable
      ).then(() => {});
    });

    setOpen({ open: false, val: "" });
  };
  return photo?.length > 0 ? (
    <Box className="latest-content">
      <Typography>{process.env.REACT_APP_TOP_PHOTOS_TITLE_TEXT}</Typography>
      <div className="rec-carousel">
        <div className="rec-carousel-item">
          <div className="jZpChr">
            <div
              className="events"
              itemsToShow={3}
              outerSpacing={0}
              pagination={false}
            >
              {photo.map((ele) => {
                let isFree;
                if (testUser) {
                  isFree = true;
                } else {
                  isFree = ele.noOfGems == 0;
                }
                let isPurchased;
                if (testUser) {
                  isPurchased = true;
                } else {
                  isPurchased =
                    myPurchedPhotos.filter(
                      (pho) => pho.vdoid == ele.id || pho.photoid == ele.id
                    ).length > 0;
                }
                const lockedImage = !isPurchased && !isFree;
                const lockedStrip = !isFree
                  ? `${
                      !isPurchased
                        ? process.env.REACT_APP_LOCKED_STRIP_TEXT
                        : process.env.REACT_APP_UNLOCKED_STRIP_TEXT
                    } Image`
                  : false;

                return (
                  <div
                    className="items"
                    onClick={() =>
                      isFree || isPurchased
                        ? setOpenPhoto({ open: true, val: ele })
                        : setOpen({ open: true, val: ele })
                    }
                  >
                    {lockedStrip && (
                      <div className="strip-text typography-loginpage page-label">
                        {!isPurchased ? (
                          <LockIcon style={{ fontSize: "12px" }} />
                        ) : (
                          <LockOpenIcon style={{ fontSize: "12px" }} />
                        )}
                        &nbsp; {lockedStrip}
                      </div>
                    )}
                    {lockedImage && (
                      <div className="lockedImageIcon">
                        <LockIcon />
                      </div>
                    )}
                    <img
                      style={{
                        filter: lockedImage ? "blur(10px)" : "",
                      }}
                      className={lockedImage ? "locked-image" : ""}
                      src={ele?.photoUrl}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {open.open && (
        <ModalforPurchase
          open={open.open}
          val={open}
          wallet={wallet}
          close={() => handleClose()}
          handlePurchase={(ele) => handlePurchase(ele)}
        />
      )}
      {openPhoto.open && (
        <Opimg
          open={openPhoto.open}
          close={() => closePhoto()}
          val={openPhoto.val}
          from="topphotos"
        />
      )}
    </Box>
  ) : null;
};

export default TopPhotos;
