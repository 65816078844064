import React, { useEffect, useState } from "react";
import "./Dmpage.scss";
import profile from "../Images/profile.jpg";
import AddIcon from "@mui/icons-material/Add";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import InfiniteScroll from "react-infinite-scroll-component";
import { db } from "../../firebaseConfig/firebaseConfig";
import { useNavigate } from "react-router-dom";
function HandSDmPage() {
  const navigate = useNavigate();
  const [lastDoc, setLastDoc] = useState(null);
  const [messages, setMessages] = useState([]);

  const getData = (lastDoc = null) => {
    let q4;
    if (lastDoc) {
      q4 = query(
        collection(
          db,
          process.env.REACT_APP_HELP_AND_SUPPORT_CHAT_COLLECTION_NAME
        ),
        where("lastChatMessage", "!=", process.env.REACT_APP_INITIAL_MESSAGE),
        orderBy("lastChatMessage"),
        orderBy("lastMessageTime", "desc"),
        startAfter(lastDoc),
        limit(15)
      );
    } else {
      q4 = query(
        collection(
          db,
          process.env.REACT_APP_HELP_AND_SUPPORT_CHAT_COLLECTION_NAME
        ),
        where("lastChatMessage", "!=", process.env.REACT_APP_INITIAL_MESSAGE),
        orderBy("lastChatMessage"),
        orderBy("lastMessageTime", "desc"),
        limit(15)
      );
    }

    onSnapshot(q4, (querySnapshot) => {
      let chats = [];
      setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
      querySnapshot.forEach((doc) => {
        const lastChatMessage = doc.data().lastChatMessage;
        if (lastChatMessage !== process.env.REACT_APP_INITIAL_MESSAGE) {
          chats.push({ ...doc.data(), userId: doc.id });
        }
      });
      setMessages([...messages, ...chats]);
    });
  };

  useEffect(() => {
    getData();
    window.scrollTo({ behavior: "smooth" });
  }, []);

  return (
    <>
      <Box
        className="commentdiv"
        style={{
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
        }}
      >
        <InfiniteScroll
          dataLength={messages.length}
          next={() => {
            getData(lastDoc);
          }}
          style={{ display: "flex", flexDirection: "column" }}
          scrollableTarget="dapage"
          hasMore={true}
          scrollThreshold={"300px"}
          initialScrollY={0}
        >
          {messages.map((val) => {
            let isGif = val.lastChatMessage.includes(".gif");
            let isImage = val.lastChatMessage.includes("https://");
            return (
              <>
                <Box
                  className="comment"
                  onClick={() =>
                    navigate("/back/modalsideuserhelpandsupportchats", {
                      state: { val },
                    })
                  }
                >
                  {val.dpUrl ? (
                    <img alt="" src={val.dpUrl} className="imgprofile" />
                  ) : (
                    <img alt="" src={profile} className="imgprofile" />
                  )}
                  <Box id="cmnt">
                    <Typography variant="h6" id="user">
                      {val.name}
                    </Typography>
                    <Typography variant="body1" id="msgs">
                      {isGif
                        ? `[ANIMATED GIF]`
                        : isImage
                        ? "[IMAGE]"
                        : val.lastChatMessage}
                    </Typography>
                  </Box>
                  {val.totalUnreadCount !== 0 ? (
                    <Box className="cmcount">
                      <Typography variant="body2">
                        {val.totalUnreadCount}
                      </Typography>
                    </Box>
                  ) : (
                    ""
                  )}
                </Box>
                <Box className="cmline"></Box>
              </>
            );
          })}
        </InfiniteScroll>
        <Box className="add" style={{ bottom: "35px" }}>
          <AddIcon
            onClick={() =>
              navigate("/back/serchuser", { state: { val: "HnSdmpage" } })
            }
          />
        </Box>
      </Box>
    </>
  );
}

export default HandSDmPage;
