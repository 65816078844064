import React, { useEffect, useRef, useState } from "react";
import "./ModalSideVideoCall.scss";
import { collection, deleteDoc, doc, onSnapshot, query, updateDoc, where } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import CallEndIcon from "@mui/icons-material/CallEnd";
import MicIcon from '@mui/icons-material/Mic';
import { Box, Button, Dialog, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import FlipCameraIosIcon from '@mui/icons-material/FlipCameraIos';
import { db } from "../../firebaseConfig/firebaseConfig";
import { MicOff, VideoCall } from "@mui/icons-material";
import { toast } from "react-toastify";

export const VideoPlayerForAdmin = ({ user, index, videocallobject,control,switchCamera,muted }) => {
  const ref = useRef();
  const [openModalView, setOpenModalView] = useState(false);
  const [mute, setMute] = useState(false);
  const navigate = useNavigate();
  const [open,setOpen] = useState(false);
  const [videoCallData, setVideoCallData] = useState({});
  useEffect(() => {
    user.videoTrack.play(ref.current);
    getData();
  }, []);
  const notify = () => toast(process.env.REACT_APP_VIDEO_CALL_END_TOAST_TEXT,{toastId: 1});

  const getData = () => {
      const q1 = query(doc(db, process.env.REACT_APP_CALL_COLLECTION_NAME, videocallobject?.docId));
    onSnapshot(q1,(querySnapshot)=>{
        setVideoCallData({...querySnapshot.data()});
    })
  }
if(videoCallData?.call_end || videoCallData?.rejected_by_user){
    navigate(-1);
    notify()
}
  const handleCallReject = () => {
    updateDoc(doc(db, process.env.REACT_APP_CALL_COLLECTION_NAME, videocallobject?.docId), {
      call_end: true,
    }).then((result) => {
        setOpen(false);
        
        notify();
        deleteDoc(doc(db, process.env.REACT_APP_CALL_COLLECTION_NAME, result.id));
        navigate(-1);
    });
  };

  const handleMic = () => {
    control();
    setMute(!mute);
  }

   const handleCameraFlip = () => {
    switchCamera();
   }

  return (
    <div>
      {index !== 0 && (
        <div
          ref={ref}
          className={`${openModalView ? "is-modal" :"not-is-modal"}`}
          id="indexzero"
          onClick={()=>{
          
            setOpenModalView(true);
            document.getElementById("indexone").className = openModalView ? "is-modal" :"not-is-modal";
            document.getElementById("indexzero").className = openModalView ? "not-is-modal" : "is-modal";}
          }
         
        ></div>
      )}
      {index === 0 && (
        <div
          ref={ref}
          className={`${openModalView ?  "not-is-modal" : "is-modal"}`}
          muted
          id="indexone"
          onClick={()=>{
            setOpenModalView(false);
            document.getElementById("indexone").className = openModalView ? "is-modal" :"not-is-modal";
            document.getElementById("indexzero").className = openModalView ? "not-is-modal" : "is-modal";}
          }
        ></div>
      )}
      {index == 0 && <Box style={{width: "100vw", height: "100vh", position: "fixed", backgroundColor: "var(--pink)", top: "0px", left: "0px"}}><Typography className="text-shadow-typography" style={{position: "fixed", top: "50%", color: "var(--white)",left:"45%"}}>Ringing...</Typography></Box> }
      { (
        <Box
          style={{
            zIndex: "5000",
            position: "fixed",
            bottom: "30px",
            width: "60vw",
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: "20vw",
            paddingRight: "20vw",
            alignItems: "center",
            left:"0px"
          }}
        >
          {!mute ? <MicIcon  className="call-icon input-field-shadow"
            style={{
              backgroundColor: "var(--white)",
              padding: "10px",
              borderRadius: "50%",
              color:"var(--blueLight)",
            }} onClick={()=>handleMic()}/>
            :<MicOff
          className="call-icon input-field-shadow"
          style={{
            backgroundColor: "var(--blueLight)",
            padding: "10px",
            borderRadius: "50%",
            color: "var(--white)",
          }} onClick={()=>handleMic()}/>}
            <FlipCameraIosIcon  className="call-icon input-field-shadow"
            style={{
              backgroundColor: "var(--white)",
              padding: "10px",
              color:"var(--blueLight)",
              borderRadius: "50%",
            }} onClick={()=>handleCameraFlip()}/>
          <CallEndIcon
            className="call-icon input-field-shadow"
            style={{
              backgroundColor: "var(--red)",
              padding: "10px",
              borderRadius: "50%",
            }}
            onClick={() => setOpen(true)}
          />
        </Box>
      )}
     {open && <Dialog open={open} aria-labelledby="responsive-dialog-title " className="loguotpop" style={{backgroundColor:'var(--pinkDark)'}}> 
        <DialogTitle className="dialog-title-background typography-loginpage">
          {process.env.REACT_APP_DIALOG_APP_NAME}
        </DialogTitle>
      
        <DialogContent>
          <DialogContentText className="typography-loginpage" >
            {process.env.REACT_APP_EXIT_CALL_MODAL_TEXT}
          </DialogContentText>
          <Button className="typography-loginpage loginpage-button"  onClick={()=> handleCallReject()}>Yes</Button>
          <Button className="typography-loginpage loginpage-button" onClick={() => setOpen(false)}>No</Button>
        </DialogContent>
      </Dialog>}
    </div>
  );
};
