import React, { useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Typography,
  Box,
  Button,
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import Logo from "../../assests/logo.png";
import "./Menu.scss";
import "../../App.scss";
import Help from "../../assests/help_drawer_icon.png";
import SuperFan from "../../assests/superfan_drawer_icon.png";
import MyGifts from "../../assests/my_purchase_icon.png";
import Logout from "../../assests/logout_icon.png";
import Profile from "../../assests/profile_icon.png";
import Wallet from "../../assests/wallet_drawer_icon.png";
import RechargeGems from "../../assests/user_recharge_drawer_icon.png";
import Settings from "../../assests/setting_icon.png";
import Close from "../../assests/baseline_close_white_36dp.png";
import { useNavigate } from "react-router-dom";
import ModalComponent from "../ModalComponent/ModalComponent";
import { deleteDoc } from "firebase/firestore";
import { db } from "../../firebaseConfig/firebaseConfig";
import { getAuth, signOut } from "firebase/auth";
import UpdateGemsIcon from "../../assests/gems_drawer_icon.png"
import ShopIcon from "../../assests/shop_drawer_icon.png"
import ShoutOutIcon from "../../assests/notification_drawer_icon.png"
import UploadBannerIcon from "../../assests/banner_drawer_icon.png"

const Menu = (props) => {
  const [openl, setOpenl] = useState(false)
  const [open, setOpen] = useState(props.open);
  const auth = getAuth();

    const navigate = useNavigate();

  const routes = {
    0: "/mainback/mypurchase",
    1: "/mainback/myprofile",
    2:"/back/wallet",
    3: "/mainback/rechargepage",
    4: "/mainback/userhelp",
    5: "/mainback/setting",
    7: "/mainback/giftme",
    6: "/back/superfan",
    8: "/",
    12:"/helpandsupport",
    13:"/back/uploadbanner",
    14:"/back/upadetegems",
    15:"/back/model_shoutout",
  };
  const handleClick = (key) => {
    if(key == 8){
    localStorage.clear();
   signOut(auth).then(() => {
    }).catch((error) => {
});}
    const route = routes[key];
    setOpen(!open);
    if (route) navigate(route);
    else console.warn(`${key} not in routes map`);
  };
const logout =()=>{
  setOpenl(!openl);
  setOpen(false);
  
}
let isModal = JSON.parse(localStorage.getItem(process.env.REACT_APP_ISMODAL_FLAG));
  const drawer = (
    <div className="drawer-menu">
      <Box>
        <img src={Logo} />
      </Box>
      <List>
         <ListItem onClick={()=>handleClick(0)} key="0" disablePadding className="menuitem">
          <img src={MyGifts} />
          <Button>
            <ListItemText primary="My Purchases" className="listitem" />
          </Button>
        </ListItem>
        <ListItem onClick={() => handleClick(1)} key="1" disablePadding className="menuitem">
          <img src={Profile} />
          <Button>
            <ListItemText primary="Profile" className="listitem" />
          </Button>
        </ListItem>

        <ListItem onClick={()=>handleClick(2)} key="2" disablePadding className="menuitem">
          <img src={Wallet} />
          <Button>
            <ListItemText primary="Wallet" className="listitem" />
          </Button>
        </ListItem>
        <ListItem onClick={()=>handleClick(3)} key="3" disablePadding className="menuitem">
          <img src={RechargeGems} />
          <Button>
            <ListItemText primary="Recharge Gems" className="listitem" />
          </Button>
        </ListItem>
        <ListItem onClick={() => handleClick(4)} key="4" disablePadding className="menuitem">
          <img src={Help} />
          <Button>
            <ListItemText primary="Help and Support" className="listitem" />
          </Button>
        </ListItem>
        <ListItem onClick={()=>handleClick(5)} key="5" disablePadding className="menuitem">
          <img src={Settings} />
          <Button>
            <ListItemText primary="Settings" className="listitem" />
          </Button>
        </ListItem>
        <ListItem onClick={()=>handleClick(6)} key="6" disablePadding className="menuitem">
          <img src={SuperFan} />
          <Button>
            <ListItemText primary="SuperFan" className="listitem" />
          </Button>
        </ListItem>
        <ListItem onClick={()=>handleClick(7)} key="7" disablePadding className="menuitem">
          <img src={MyGifts} />
          <Button>
            <ListItemText primary="Gift Me" className="listitem" />
          </Button>
        </ListItem>

        <ListItem onClick={()=>logout()}  key="8" disablePadding className="menuitem ">
          <img src={Logout} />
          <Button>
            <ListItemText primary="Logout" className="listitem " />
          </Button>
        </ListItem>
      </List>
      <Box>
        <img
          className="close"
          src={Close}
          onClick={()=>props.close()}
        />
      </Box>
      <Typography className="app-version">App Version : {process.env.REACT_APP_VERSION}</Typography>
      
    </div>
  );
  const drawerModal = (
    <div className="drawer-menu">
      <Box>
        <img src={Logo} />
      </Box>
      <List>
         <ListItem disablePadding className="menuitem" onClick={()=>handleClick(15)}>
          <img src={ShoutOutIcon} />
          <Button>
            <ListItemText primary="ShoutOuts" className="listitem" />
          </Button>
        </ListItem>
        <ListItem disablePadding className="menuitem" onClick={()=>handleClick(13)}>
          <img src={UploadBannerIcon} />
          <Button>
            <ListItemText primary="Upload Banners" className="listitem" />
          </Button>
        </ListItem>

        <ListItem disablePadding className="menuitem" onClick={()=>handleClick(14)}>
          <img src={UpdateGemsIcon} />
          <Button>
            <ListItemText primary="Update Gems" className="listitem" />
          </Button>
        </ListItem>
     
        <ListItem onClick={()=>handleClick(12)} key="12" disablePadding className="menuitem">
          <img src={Help} />
          <Button>
            <ListItemText primary="Help and Support" className="listitem" />
          </Button> 
        </ListItem>
        {/* <ListItem disablePadding className="menuitem">
          <img src={ShoutOutIcon} />
          <Button>
            <ListItemText primary="Send Notifications" className="listitem" />
          </Button>
        </ListItem> */}
        <ListItem  disablePadding className="menuitem" onClick={()=>handleClick(6)}>
          <img src={SuperFan} />
          <Button>
            <ListItemText primary="SuperFan" className="listitem" />
          </Button>
        </ListItem>
        {/* <ListItem  disablePadding className="menuitem">
          <img src={ShopIcon} />
          <Button>
            <ListItemText primary="My Shops" className="listitem" />
          </Button>
        </ListItem> */}
        {/* <ListItem  disablePadding className="menuitem">
          <img src={MyGifts} />
          <Button>
            <ListItemText primary="My Gifts" className="listitem" />
          </Button>
        </ListItem> */}

        <ListItem onClick={()=>logout()} key="8" disablePadding className="menuitem ">
          <img src={Logout} />
          <Button>
            <ListItemText primary="Logout" className="listitem " />
          </Button>
        </ListItem>
      </List>
      <Box>
        <img
          className="close"
          src={Close}
          onClick={()=>props.close()}
        />
      </Box>
      <Typography className="app-version">App Version : {process.env.REACT_APP_VERSION}</Typography>
      
    </div>
  );

  return (
  <> {open && <Box className='general'>
      <Drawer variant="temporary" open={open}>
        {isModal ? drawerModal : drawer}
      </Drawer>
    </Box>
 }
    {!open && openl && <Box >
      <Box >
      <Dialog open={openl} onClose={()=>setOpenl(false)} aria-labelledby="responsive-dialog-title " className="loguotpop" > 
        <DialogTitle className="dialog-title-background typography-loginpage">
          {process.env.REACT_APP_DIALOG_APP_NAME}
        </DialogTitle>
      
        <DialogContent>
          <DialogContentText className="typography-loginpage" >
            Are you sure you want to Sign out?
          </DialogContentText>
          <Button className="typography-loginpage loginpage-button"  onClick={()=> handleClick(8)}>Yes</Button>
          <Button className="typography-loginpage loginpage-button" onClick={() => setOpenl(false)}>No</Button>
        </DialogContent>
      </Dialog>
      </Box>
    </Box>}
    </>
  );
};

export default Menu;
