import { Box, Button, Grid, Typography } from "@mui/material";
import VideocamIcon from "@mui/icons-material/Videocam";
import MenuIcon from "@mui/icons-material/Menu";
import React, { useEffect, useState } from "react";
import logo from "../../../assests/logo.png";
import jems from "../../../component/Images/jems.png";
import "./Mainhead.scss";
import { Outlet } from "react-router-dom";
import Menu from "../../../components/Navbar user/Menu";
import { getAuth } from "firebase/auth";
import { collection, doc, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../../../firebaseConfig/firebaseConfig";
import { useNavigate } from "react-router-dom";

function AdminHead() {
  const auth = getAuth();
  const navigate = useNavigate();
  let isModal = JSON.parse(localStorage.getItem("isModal"));
  const [open, setOpen] = useState(false);
  const [wallet, setWallet] = useState(
    JSON.parse(localStorage.getItem("wallet"))
  );

  useEffect(() => {
    if (!isModal) {
      getData();
    }
  }, []);

  const getData = () => {
    const q = query(
      collection(db, "wallet"),
      where("uid", "==", JSON.parse(localStorage.getItem("users")).idUser)
    );
    onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        setWallet(doc.data());
      });
    });
  };

  const handleClose = () => {
    setOpen(false);
  };
  const videocallpage = () => {
    navigate("/mainback/uservideocall", { state: { muted: false } });
  };
  return (
    <Box className="hh">
      <Box className="header">
        <Grid container className="headbox">
          <Grid item xs={1.5}>
            <Box>
              <Box onClick={() => setOpen(!open)} className="menubtn">
                <MenuIcon />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <img alt="" src={logo} className="adminLogo" />
          </Grid>

          <Grid
            item
            xs={3}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Button
              onClick={videocallpage}
              className="button-color"
              id="videobtn"
              variant="contained"
              startIcon={<VideocamIcon />}
            >
              CALL
            </Button>
          </Grid>
          {open ? <Menu open={open} close={() => handleClose()} /> : ""}
        </Grid>
        <Box className="cmline"></Box>
      </Box>
      <Box>
        <Outlet />
      </Box>
    </Box>
  );
}

export default AdminHead;
