import React, { useEffect, useState } from "react";
import "./HomeFeed.scss";
import ProfileViewVideo from "./ProfileViewVideo.js";
import GridViewVideo from "./GridViewVideo.js";
import { useNavigate } from "react-router-dom";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
} from "firebase/firestore";
import { db } from "../../../firebaseConfig/firebaseConfig";
import { getAuth } from "firebase/auth";
import Spinners from "../../../components/Spinner/Spinners";
import Grid_view from "./Grid_view";

const Videos = ({ currentPage, view }) => {
  const auth = getAuth();
  const [loading, setLoading] = useState(true);
  const [lastDoc, setLastDoc] = useState(null);
  const navigate = useNavigate();
  const [video, setVideo] = useState({});

  useEffect(() => {
    getVideosData();
    window.scrollTo({ top: 0, behavior: "smooth" });
    setLoading(false);
  }, []);

  const getVideosData = (lastDoc = null) => {
    let q4;
    if (lastDoc) {
      q4 = query(
        collection(db, process.env.REACT_APP_VIDEOS_COLLECTION),
        orderBy("createdAt", "desc"),
        startAfter(lastDoc),
        limit(15)
      );
    } else {
      q4 = query(
        collection(db, process.env.REACT_APP_VIDEOS_COLLECTION),
        orderBy("createdAt", "desc"),
        limit(15)
      );
    }

    getDocs(q4).then((result) => {
      let videos = {};
      setLastDoc(result.docs[result.docs.length - 1]);
      result.forEach((doc) => {
        videos = {
          ...videos,
          [doc.id]: {
            ...doc.data(),
            id: doc.id,
            likedCounter: 0,
            commentCounter: 0,
            myLiked: false,
          },
        };
      });
      setVideo({ ...video, ...videos });
      getLikedCounters(videos);
    });
  };

  const getLikedCounters = (videos) => {
    const promises = Object.keys(videos).map((id) => {
      const q1 = query(
        collection(
          db,
          process.env.REACT_APP_VIDEOS_COLLECTION,
          id,
          process.env.REACT_APP_LIKED_COLLECTION
        )
      );
      return getDocs(q1);
    });
    const promisesComments = Object.keys(videos).map((id) => {
      const q2 = query(
        collection(
          db,
          process.env.REACT_APP_VIDEOS_COMMENTS_COLLECTION,
          id,
          process.env.REACT_APP_COMMENTS_COLLECTION
        )
      );
      return getDocs(q2);
    });
    Promise.all([...promises, ...promisesComments]).then((values) => {
      values.map((data) => {
        if (!data.empty) {
          const path = data.query.path.split("/");
          const docID = path[1];
          if (path[2] == "comments") {
            videos[docID].commentCounter = data.docs.length;
          }  else {
            videos[docID].likedCounter = data.docs.length;
          }
        }
      });
      setVideo({ ...video, ...videos });
    });
  };
  return currentPage == "video" ? loading ? ( <Spinners /> ) :  (
    view == "profile" ? (
      <ProfileViewVideo state={currentPage} paginate={getVideosData} lastDoc={lastDoc} data={video} />
    ) : (
      <GridViewVideo state={currentPage} paginate={getVideosData} lastDoc={lastDoc} data={video} />
    )
  ) : null;
};

export default Videos;
