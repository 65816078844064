import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import React from "react";
import jems from "../../../component/Images/jems.png";
import { Close } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const InsufficientBalance = (props) => {
  const navigate = useNavigate();

  return (
    <Dialog
      open={props.open}
      onClose={() => props.close()}
      className="photopopup"
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle id="modeltext" className="context-div">
        {process.env.REACT_APP_INSUFFICIENT_BALANCE}
      </DialogTitle>
      <Divider style={{ backgroundColor: "var(--white)" }} />

      <DialogContent id="context">
        <DialogContentText className="context-div">
          <Typography variant="h6" id="modeltext">
            {process.env.REACT_APP_WALLET_BALANCE_TEXT}: {props.wallet?.balance}
          </Typography>
          <img alt="" src={jems} className="jemsimg" />
        </DialogContentText>
      </DialogContent>

      <DialogContent id="context">
        <DialogContentText className="context-div">
          <Typography variant="h6" id="modeltext">
            {process.env.REACT_APP_NEED_GEMS_FOR_TEXT}:{" "}
            {parseInt(props.shoutoutPricing) - parseInt(props.wallet?.balance)}
          </Typography>
          <img alt="" src={jems} className="jemsimg" />
        </DialogContentText>
      </DialogContent>

      <DialogActions className="modelbtndiv">
        <Button
          variant="contained"
          id="popbtn"
          onClick={() => navigate("/back/wallet")}
        >
          {process.env.REACT_APP_BUY_FOR_TEXT}
          {parseInt(props.shoutoutPricing)}
          <img alt="" src={jems} className="jemsimg" />
        </Button>

        <Button variant="contained" onClick={() => props.Close()} id="popbtn">
          {process.env.REACT_APP_MODAL_CANCEL_BUTTON_TEXT}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InsufficientBalance;
